import React, { useEffect, useState, useContext } from 'react';

import { Flex, Switch, Typography, Card, Badge } from 'antd';
const { Paragraph } = Typography;

import AllergensIcons from '../../shared/AllergensIcons';
import SpiceLevel from '../../shared/SpiceLevel';
import ItemModifiers from './ItemModifiers';

import { LanguageContext } from 'contexts/LanguageContext';
import { CartContext } from 'contexts/CartContext';

import * as menuStyles from '../Menu.module.scss';

const ItemCard = ({ item }) => {
  const cart = useContext(CartContext);
  const langContext = useContext(LanguageContext);

  const [intro, setIntro] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    let pickedText = ""

    if (langContext.language == 'pt') {
      pickedText = (item.description_pt || item.description_en || "")
    } else {
      pickedText = (item.description_en || item.description_pt || "")
    }

    const splatPickedText = pickedText.split('---')

    const pickedIntro = (splatPickedText[0 | ""]).trim() //.split(/(?:\r\n|\r|\n)/).join(" || ")
    const pickedDesc = (splatPickedText[1] || "").trim() //.split(/(?:\r\n|\r|\n)/).join(" || ")

    setIntro(pickedIntro)
    setDescription(pickedDesc)

  }, [langContext.language, item])

  const itemOutOfStock = item.availability_status == 1;
  const itemCanBeOrdered = (item.availability_status == 2 && item.available_in?.length > 0 && item.available_in.includes(cart.fulfilmentType));

  const itemCardContents = <Card
    style={{ width: "100%" }}
    cover={item.photo_url ? <img
      src={item.photo_url}
      alt={`Photo of ${item.name}`}
      title={item.name}
      loading="eager" /> : null}
    actions={[
      <AllergensIcons item={item} />,
      <SpiceLevel level={item.spice_level} />
    ]}>
    <Card.Meta
      title={<span style={(itemCanBeOrdered ? {} : { color: '#aaa' })}>{item.name}</span>}
      style={(itemCanBeOrdered ? {} : { color: '#aaa' })}
      description={<Paragraph ellipsis={{
        rows: (itemCanBeOrdered ? 3 : 1),
        expandable: true,
        symbol: '(more)',
      }} style={(itemCanBeOrdered ? {} : { color: '#aaa' })}>
        {intro.length > 0 && intro}<br />{description.length > 0 && description}
        {(intro + description).length > 0 && <><br /><Flex justify="end">
          <Switch size="small" style={{ backgroundColor: langContext.language == 'pt' ? 'green' : 'blue' }}
            checked={langContext.language == 'pt'}
            checkedChildren={'pt'}
            unCheckedChildren={'en'}
            onChange={langContext.toggleLanguage} />
        </Flex></>}
      </Paragraph>}
    />

    <ItemModifiers item={item} canOrder={itemCanBeOrdered} />
  </Card>

  const ribbonedCard = (cardContents) => {
    if (itemOutOfStock) {
      return <Badge.Ribbon rootClassName={menuStyles.ribbonWrapper} color="#333" text="Out of stock">
        {cardContents}
      </Badge.Ribbon>;
    } else if (item.availability_status == 2) {
      if (item.available_in.length > 0 && !item.available_in.includes(cart.fulfilmentType)) {
        return <Badge.Ribbon rootClassName={menuStyles.ribbonWrapper} color="#333" text={`Not available in ${cart.fulfilmentType}`}>
          {cardContents}
        </Badge.Ribbon>;
      }
    }

    return <Flex className={menuStyles.ribbonWrapper}>{cardContents}</Flex>;
  }

  return <div className={menuStyles.itemCardWrapper}>
    {ribbonedCard(itemCardContents)}
  </div>
};

export default ItemCard