import React, { createContext, useEffect, useState } from 'react'

import { useLocalStorage } from "@uidotdev/usehooks";

export const SessionContext = createContext({
  tableNumber: null,
  currentUser: null
})

export default function SessionContextProvider({ value, children }) {
  const [tableNumber, setTableNumber] = useState()
  const [currentUser, setCurrentUser] = useState()

  const [customerId, saveCustomerId] = useLocalStorage("customerId", null);
  const [tableNumberInStorage, saveTableNumberInStorage] = useLocalStorage("tableNumberInStorage", null);
  const [tableNumberInStorageTimestamp, saveTableNumberInStorageTimestamp] = useLocalStorage("tableNumberInStorageTimestamp", null);
  const [shouldClearCart, saveShouldClearCart] = useLocalStorage("shouldClearCart", null);

  const timeBetweenTableRefreshes = 60 * 1000; // 1 minutes

  useEffect(() => {
    if (tableNumberInStorage) {
      if (tableNumberInStorageTimestamp && (new Date() - new Date(tableNumberInStorageTimestamp)) < timeBetweenTableRefreshes) {
        console.log("Getting table number from storage: ", tableNumberInStorage)
        setTableNumber(tableNumberInStorage)
      } else {
        console.log("Clearing table number from storage")
        setTableNumber(null)
      }
    }

    if (window?.location?.search) {
      const queryParameters = new URLSearchParams(window.location.search)
      if (queryParameters.get("table")) {
        console.log("Table number from query parameters: ", queryParameters.get("table"))
        setTableNumber(queryParameters.get("table"))
      }
    }
  }, [])

  useEffect(() => {
    if (tableNumber) {
      console.log("Saving table number in storage: ", tableNumber)
      saveTableNumberInStorage(tableNumber)
      saveTableNumberInStorageTimestamp(new Date().toISOString())
    }
  }, [tableNumber])

  return <SessionContext.Provider value={{ tableNumber, currentUser, setTableNumber, saveShouldClearCart, shouldClearCart }}>{children}</SessionContext.Provider>
}
