import React, { useEffect, useState } from 'react'

import classNames from 'classnames';

import { Flex, Typography, Tooltip } from 'antd';
const { Text } = Typography;

import flameIcon from '../../images/new/fire.svg'
import faceFire from '../../images/new/fire-face.gif'
import smallFire from '../../images/new/fire-small.gif'
import slowFire from '../../images/new/fire-slow.gif'

import * as menuStyles from '../Menu/Menu.module.scss';

const SpiceLevel = ({ level }) => {
  const [flames, setFlames] = useState([]);

  useEffect(() => {
    let flames = [];

    if (level == 0) {
      flames.push(
        <img className={classNames([menuStyles.itemFlamesIcon, menuStyles.itemFlamesIconNotSpicy])} key={0} src={flameIcon} alt={`Spice level: ${level}`} title='Spice level icon' />
      );
    } else {
      for (let i = 0; i < level; i++) {
        flames.push(
          <img className={menuStyles.itemFlamesIcon} key={i} src={smallFire} alt={`Spice level: ${level}`} title='Spice level icon' />
        );
      }
    }

    setFlames(flames);
  }, [])

  return (level > 0 ? <Flex vertical align='center' style={{ position: 'relative', bottom: '-0.5rem' }}>
    <Tooltip title={`Spicy level: ${level}`}>
      <Text style={{
        position: 'absolute',
        top: '-2rem',
        background: 'rgba(255, 255, 255, 0.7)',
        padding: '0 0.3rem'
      }}>Spice level</Text>
    </Tooltip>
    <Flex gap={6} style={{ marginTop: '-1.1rem' }}>
      <Tooltip title={`Spicy level: ${level}`}>{flames}</Tooltip>
    </Flex>
  </Flex> : <Flex vertical align='center'>
    <Text>Not spicy at all</Text>
  </Flex>);
}

export default SpiceLevel