import React, { lazy, Suspense, useEffect } from 'react'

const WaitingPayment = lazy(() => import('./WaitingPayment'));
const WaitingAcceptance = lazy(() => import('./WaitingAcceptance'));
const Delivering = lazy(() => import('./Delivering'));
const Prepared = lazy(() => import('./Prepared'));
const Accepted = lazy(() => import('./Accepted'));
const Scheduled = lazy(() => import('./Scheduled'));
const CancelledByKitchen = lazy(() => import('./CancelledByKitchen'));
const FinalThankYou = lazy(() => import('./FinalThankYou'));
const Fallback = lazy(() => import('./Fallback'));

const stateToComponentMap = {
  'pending_kitchen_acceptance': WaitingAcceptance, 
  'pending_payment': WaitingPayment,
  'accepted': Accepted,
  'prepared': Prepared,
  'picked_by_courier': Delivering,
  'cancelled_by_kitchen': CancelledByKitchen,
  'cancelled': CancelledByKitchen,
  'scheduled': Scheduled,
  'picked_by_customer': FinalThankYou,
  'delivered_to_customer': FinalThankYou
}

const DynamicState = ({ order }) => {
  let ComponentForState = stateToComponentMap[order.aasm_state] || Fallback;

  if (order.payment_method.method == "offline" && order.aasm_state == 'pending_payment') {
    ComponentForState = Accepted
  }

  return <Suspense fallback={<div>Loading...</div>}>
      <ComponentForState order={order} />          
  </Suspense>
}

export default DynamicState