import React, { Suspense, useState, useEffect, useContext } from 'react';

import * as Sentry from '@sentry/react';

import { CartContext } from '../../../contexts/CartContext';
// import { SessionContext } from 'contexts/SessionContext';

import LoadingSpinner from '../LoadingSpinner';

import { CreditCardOutlined } from '@ant-design/icons';

import {
    PaymentElement,
    ExpressCheckoutElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

import {
    Alert,
    Button,
    Spin,
    Checkbox,
    Typography,
    Divider
} from 'antd';

const { Text } = Typography;

import * as styles from '../../Menu/components/ShoppingCart.module.scss';

const PaymentWidget = ({ currentUser, createOrderWithIntentAndReturnUuid, verifyAllDataBeforeOrdering }) => {
    const stripe = useStripe();
    const elements = useElements();

    const cart = useContext(CartContext);
    // const session = useContext(SessionContext);

    const [showSpinner, setShowSpinner] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const [hasError, setHasError] = useState(true);
    const [wantsCardPayment, setWantsCardPayment] = useState(false);
    const [message, setMessage] = useState(null);
    const [offlinePayment, setOfflinePayment] = useState(false)

    const [expressElementIsReady, setExpressElementIsReady] = useState(false);
    const [paymentElementIsReady, setPaymentElementIsReady] = useState(false);

    useEffect(() => {
        if (cart.getTotalInCents() > 50 && elements) {
            elements.update({ amount: cart.getTotalInCents() })
        }
    }, [cart.total])

    useEffect(() => {
        if (offlinePayment) {
            cart.setPaymentMethod("offline")
            setMessage("")
            setIsReady(true)
        } else {
            cart.setPaymentMethod("card")
        }
    }, [offlinePayment])

    const expressCheckoutSubmitHandler = async (evt) => {
        const { error: submitError } = await elements.submit();
        if (submitError) {
            Sentry.captureException(submitError)
            makeNotReadyFor(3);
            setHasError(true)

            if (submitError.type == "validation_error") {
                setMessage(submitError.message)
            }
            return false;
        } else {
            setOfflinePayment(false);
            setWantsCardPayment(true);

            await paymentFormSubmitHandler();
        }
    }

    const cardPaymentSubmitHandler = async (evt) => {
        const { error: submitError } = await elements.submit();
        if (submitError) {
            Sentry.captureException(submitError)
            makeNotReadyFor(3);
            setHasError(true)

            if (submitError.type == "validation_error") {
                setMessage(submitError.message)
            }
            return false;
        } else {
            setOfflinePayment(false);
            setWantsCardPayment(true);

            await paymentFormSubmitHandler();
        }
    }

    const offlinePaymentSubmitHandler = async (evt) => {
        evt.preventDefault();

        setShowSpinner(true)

        await paymentFormSubmitHandler(true);
    }

    const cardPaymentOptionHandler = (evt) => {
        evt.preventDefault();

        const shouldProceed = verifyAllDataBeforeOrdering();

        if (!shouldProceed) {
            return false;
        }

        setOfflinePayment(false);
        setWantsCardPayment(true);
        setExpressElementIsReady(false);
        setPaymentElementIsReady(false);
    }

    const offlinePaymentOptionHandler = (evt) => {
        evt.preventDefault();

        const shouldProceed = verifyAllDataBeforeOrdering();

        if (!shouldProceed) {
            return false;
        }

        setIsReady(true);
        setOfflinePayment(true);
        setWantsCardPayment(false);
    }

    const paymentFormSubmitHandler = async (offline = false) => {
        setShowSpinner(true)
        makeNotReadyFor(3);

        const order = await createOrderWithIntentAndReturnUuid({ payment: { method: offline ? "offline" : "card" } })

        if (order && order.uuid) {
            console.info("got new order: ", order)

            const destUrl = `${window.location.origin}/s/${order.uuid}`

            if (!offline) {
                let fullStripeParams = {
                    confirmParams: {
                        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
                        return_url: destUrl,
                        payment_method_data: {
                            allow_redisplay: 'always'
                        }
                    },
                    redirect: 'always',
                    elements: elements,
                    clientSecret: order.stripe_client_secret
                }

                const { resultError } = await stripe.confirmPayment(fullStripeParams)
                if (resultError) {
                    makeNotReadyFor(3);
                    setHasError(true);
                    setIsLoading(false);

                    let errorMessage = '';

                    if (resultError.type == "card_error") {
                        errorMessage = `Payment error: ${resultError.message}`;
                    } else if (resultError.type == "validation_error" && resultError.code == "incomplete") {
                        errorMessage = `${resultError.message}`;
                    } else {
                        errorMessage = `Payment error: ${resultError.message}`;
                    }

                    setMessage(errorMessage);

                    Sentry.captureException(resultError)

                    setShowSpinner(false)

                    return;
                } else {
                    setIsLoading(false)
                    setIsReady(true)
                    setHasError(false)
                    setMessage("")
                    setCartToClear()
                    setShowSpinner(true)
                    // cleanupCartWithoutMessingWithIntent()
                }
            }

            setCartToClear()
            // setShowSpinner()

            window.location.href = destUrl;
        } else {
            Sentry.captureMessage(`Apparently New Order UUID isn't defined. Order=${order}`);

            setIsLoading(false)
            setShowSpinner(false)

            return false;
        }
    }

    const setCartToClear = () => {
        // session.saveShouldClearCart(true)

        // cart.cleanupCart()

        // cart.setThing("cleanupCart", true)
    }

    const basePaymentElementOptions = {
        // clientSecret: stripeClientSecret,
        // loader: 'always',
        // currency: 'eur',
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true
        },
        wallets: {
            applePay: 'never',
            googlePay: 'never',
        },
        business: {
            name: "Foodriders"
        }
    }

    const paymentElementOptions = () => {
        let composedOptions = basePaymentElementOptions

        if (currentUser) {
            if (currentUser.name) {
                composedOptions.defaultValues ||= { billingDetails: {} }

                composedOptions.defaultValues.billingDetails.name = currentUser.name
            }

            if (currentUser.phone_number) {
                composedOptions.defaultValues ||= { billingDetails: {} }

                composedOptions.defaultValues.billingDetails.phone = currentUser.phone_number
            }

            if (currentUser.email) {
                composedOptions.defaultValues ||= { billingDetails: {} }

                composedOptions.defaultValues.billingDetails.email = currentUser.email
            }
        }

        return composedOptions;
    }

    const expressCheckoutOptions = {
        layout: {
            overflow: 'never',
            maxColumns: 1,
            maxRows: 0
        },
        paymentMethods: {
            applePay: 'always',
            googlePay: 'always',
            link: 'auto',
            paypal: 'never',
            amazonPay: 'never'
        },
        buttonHeight: 44,
        buttonType: {
            applePay: 'order',
            googlePay: 'order'
        },
        buttonTheme: {
            applePay: 'black',
            googlePay: 'black'
        }

    }

    const makeNotReadyFor = (seconds) => {
        setIsReady(false);
        setTimeout(() => { setIsReady(true) }, seconds * 1000);
    }

    const expressCheckoutClickHandler = (event) => {
        cart.setPaymentMethod("card")
        setOfflinePayment(false)
        setShowSpinner(true)

        const shouldProceed = verifyAllDataBeforeOrdering();

        console.info("shouldProceed? ", shouldProceed)

        if (!shouldProceed) {
            // setIsLoading(false)
            setShowSpinner(false)

            return false;
        } else {
            // const items = Object.values(cart.items).map(el => ({ name: `${el.quantity}x ${el.item.name}`, amount: el.lineTotal * 100 }))
            const options = {
                emailRequired: true,
                phoneNumberRequired: true,
                // lineItems: items,
                business: {
                    name: "Foodriders"
                }
            };
            event.resolve(options);
        }
    };

    const goBackButtonHandler = () => {
        setExpressElementIsReady(false);
        setPaymentElementIsReady(false);
        setIsReady(false);
        setWantsCardPayment(false);
        setOfflinePayment(false);
    }

    const expressCheckoutConfirmHandler = async (event) => {
        if (!stripe) {
            return;
        }

        setShowSpinner(true)

        const { error: submitError } = await elements.submit();
        if (submitError) {
            makeNotReadyFor(3);
            setHasError(true)

            if (submitError.type == "validation_error") {
                setMessage(submitError.message)
            } else {
                console.warn("Error while calling elements.submit(): ", submitError)
            }

            Sentry.captureMessage(`Error while calling elements.submit(): ${submitError}`)

            setShowSpinner(false)

            return;
        }

        // Create a ConfirmationToken using the details collected by the Express Checkout Element
        const { error, confirmationToken } = await stripe.createConfirmationToken({ elements });
        if (error) {
            // This point is only reached if there's an immediate error when
            // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
            makeNotReadyFor(3);
            setHasError(true)
            setMessage(error.message)

            Sentry.captureException(submitError)

            console.warn("Err while stripe.createConfirmationToken ", error)

            setShowSpinner(false)
            return;
        }

        console.info("Return stripe.createConfirmationToken ", confirmationToken)

        const order = await createOrderWithIntentAndReturnUuid({
            payment: {
                method: "card"
            },
            stripe: {
                confirmation_token_id: confirmationToken.id
            }
        })

        if (order) {
            console.info("got new order: ", order)

            const { error, paymentIntent } = await stripe.retrievePaymentIntent(order.stripe_client_secret)
            if (error) {
                console.warn("Err while stripe.retrievePaymentIntent", error)
                setShowSpinner(false)
                return
            } else {
                const destUrl = `${window.location.origin}/s/${order.uuid}`

                let fullStripeParams = {
                    confirmParams: {
                        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
                        return_url: destUrl,
                        payment_method_data: {
                            allow_redisplay: 'always'
                        },
                        confirmation_token: confirmationToken.id
                    },
                    redirect: 'always',
                    elements: elements,
                    clientSecret: order.stripe_client_secret
                }

                if (paymentIntent.status === "requires_action") {
                    // Use Stripe.js to handle the required next action
                    const { error, paymentIntent } = await stripe.handleNextAction({ clientSecret: order.stripe_client_secret })

                    if (error) {
                        return;
                    } else {
                        setCartToClear()
                    }
                } else {
                    setCartToClear()
                }

                window.location.href = destUrl;
            }
        } else {
            Sentry.captureMessage(`Apparently no order was created. Response=${order}`);

            setIsLoading(false)
            setShowSpinner(false)

            return false;
        }
    }

    const expressCheckoutCancelHandler = (event) => {
        setHasError(false)
        setShowSpinner(false)

        setExpressElementIsReady(false);
        setPaymentElementIsReady(false);
        setIsReady(false);
        setWantsCardPayment(false);
        setOfflinePayment(false);
        setShowSpinner(false)

        cart.setPaymentMethod("");
    }

    return (<>
        {message && <Alert message={message} type="info" />}

        {((cart.getTotalInCents() > 50 && !wantsCardPayment && !offlinePayment) && <>
            <Suspense fallback={<LoadingSpinner />}>
                <ExpressCheckoutElement
                    onClick={expressCheckoutClickHandler}
                    onConfirm={expressCheckoutConfirmHandler}
                    onCancel={expressCheckoutCancelHandler}
                    id='express-checkout-element'
                    options={expressCheckoutOptions}
                    onReady={(availablePaymentMethods) => { if (availablePaymentMethods) { setExpressElementIsReady(true); setIsReady(true); } }}
                    style={{ marginBottom: '24px' }} />
            </Suspense>

            {!expressElementIsReady && <LoadingSpinner tip="Loading Wallets" />}
        </>)}

        {(cart.getTotalInCents() > 50 && wantsCardPayment && <>
            <Suspense fallback={<LoadingSpinner />}>
                <PaymentElement
                    id="payment-element"
                    onReady={() => { setPaymentElementIsReady(true); setIsReady(true); }}
                    options={paymentElementOptions()} />
            </Suspense>

            {!paymentElementIsReady && <LoadingSpinner tip="Loading Cards" />}
        </>)}

        {(!wantsCardPayment && !offlinePayment) && <>
            {cart.getTotalInCents() > 50 && <Button
                size='large'
                type='primary'
                htmlType='button'
                className={styles.fullPlaceOrder}
                onClick={cardPaymentOptionHandler}><CreditCardOutlined /> Order with Card</Button>}

            {cart.metadata.fulfilmentType == "tableservice" && <Button
                size='large'
                type='primary'
                htmlType='button'
                className={styles.fullPlaceOrder}
                style={{ marginBottom: '24px' }}
                onClick={offlinePaymentOptionHandler}><CreditCardOutlined /> Pay Offline</Button>}
        </>}

        {offlinePayment && <>
            <Divider>Paying Offline</Divider>
            <Text strong className={styles.offlinePaymentNotice}>💳 Our staff will assist you with payment before you leave 👋 (Card payments only)</Text>
            <Button
                size='large'
                type='primary'
                htmlType='button'
                className={styles.fullPlaceOrder}
                onClick={offlinePaymentSubmitHandler}>Place Order</Button>
        </>}

        {wantsCardPayment && <>
            <Button
                size='large'
                type='primary'
                htmlType='button'
                className={styles.fullPlaceOrder}
                disabled={!paymentElementIsReady}
                onClick={expressCheckoutConfirmHandler}>Place Order</Button>
        </>}

        {(wantsCardPayment || offlinePayment) &&
            <Button type="link" className={styles.goBackButton} onClick={goBackButtonHandler}>back</Button>}

        <Spin spinning={showSpinner} fullscreen />
    </>)
}

export default PaymentWidget;