import React, { useState, useContext } from 'react'
import classNames from 'classnames'
import { Link } from "react-router-dom"

import { Button, Collapse, Flex, Switch } from 'antd';

import { LanguageContext } from 'contexts/LanguageContext'
import { MenuContext } from 'contexts/MenuContext';

import AllergensPanel from './AllergensPanel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'

import igIcon from 'images/icons/instagram.png'
import infoIcon from 'images/icons/info.png'

import * as styles from './InfoSection.module.scss'

const InfoSection = () => {
    const langContext = useContext(LanguageContext)
    const { menu } = useContext(MenuContext);

    const [showingInfo, setShowingInfo] = useState(false)

    const toggleShowingInfo = () => {
        setShowingInfo(showing => !showing)
    }

    const goToInstagram = () => {
        window.open('https://www.instagram.com/durodematar.pt/', '_blank');
    }

    const langToggleLink = (currLang, linkLang) => {
        return <span className={classNames({ [styles.langSelectionActive]: currLang === linkLang })}>{linkLang}</span>
    }

    const toggleLang = (evt) => {
        evt.preventDefault()
        langContext.toggleLang()
    }

    const panelItems = [
        {
            key: '1',
            label: <h4 className={styles.aboutSectionTitle}>Allergens</h4>,
            children: <AllergensPanel allergens={menu?.allergens} />
        },
        {
            key: '2',
            label: <h4 className={styles.aboutSectionTitle}>Libro Reclamações</h4>,
            children: <p><a href="https://www.livroreclamacoes.pt/" target="_blank">Livro de Reclamações Online</a></p>
        },
        {
            key: '3',
            label: <h4 className={styles.aboutSectionTitle}>About</h4>,
            children: <div className={classNames(styles.menuInfo, styles.infoSectionCard)}>
                <p className={styles.menuInfoAboutText}>{menu?.about_text}</p>
            </div>
        }
    ]

    return (
        <Flex vertical className={styles.infoBar} id="infoSectionBar">
            <Flex justify='space-between' align='center'>
                <Link to="/menus" className={styles.infoSectionBackLink}><FontAwesomeIcon icon={faAngleLeft} /> Menus</Link>

                <button className={styles.infoButton} onClick={goToInstagram}>
                    <img src={igIcon} className={styles.infoIcon} />
                </button>

                <Switch size="small"
                    style={{
                        color: 'black',
                        backgroundColor: '#aaa'
                    }}
                    //  style={{ backgroundColor: langContext.language == 'pt' ? 'green' : 'blue' }}
                    checked={langContext.language == 'pt'}
                    checkedChildren={'pt'}
                    unCheckedChildren={'en'}
                    onChange={langContext.toggleLanguage} />
            </Flex>

            {showingInfo && <Collapse accordion ghost items={panelItems} />}
        </Flex>
    )
}


export default InfoSection