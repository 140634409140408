'use client';

import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';

import * as Sentry from '@sentry/react';

import {
    App,
    Form,
    Input,
    Button,
    Modal,
    Typography,
    ConfigProvider,
    Flex
} from 'antd';
const { Text } = Typography;

import PhoneInput from "antd-phone-input";

const IntlTelInput = lazy(() => import("intl-tel-input/reactWithUtils"));
// import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";
import "./intl-tel-input.css";

import Countdown from '@nyaxk/react-countdown';

import * as styles from './LoginWidget.module.scss';
import * as shoppingCartStyles from '../../Menu/components/ShoppingCart.module.scss'

const CODE_SENT_RESET_SECONDS = 15;

const LoginWidget = ({ currentUser, setCurrentUser, setCustomerDetails, customerDetails }) => {
    const { message } = App.useApp();

    const [modalOpen, setModalOpen] = useState(false);
    const [submittable, setSubmittable] = useState(false);

    const [codeHasBeenSent, setCodeHasBeenSent] = useState(false);
    const [loginCodeAcceptable, setLoginCodeAcceptable] = useState(false);
    const [codeButtonEnabled, setCodeButtonEnabled] = useState(false);
    const [codeButtonText, setCodeButtonText] = useState("Get Code");
    const [codeButtonTimeout, setCodeButtonTimeout] = useState();
    const [phoneNumberValid, setPhoneNumberValid] = useState(false);

    const [loginPhoneNumber, setLoginPhoneNumber] = useState('')
    const [loginConfirmationCode, setLoginConfirmationCode] = useState('')

    const [customerForm] = Form.useForm();
    const [loginForm] = Form.useForm();

    const authCodeRef = useRef(null);

    const showModal = () => { setModalOpen(true) }
    const hideModal = () => { setModalOpen(false) }

    // useEffect(() => {

    //     if (currentUser && customerForm) {
    //         customerForm.setFieldsValue({
    //             name: currentUser.name,
    //             email: currentUser.email,
    //             phone_number: currentUser.phone_number
    //         })
    //     }
    // }, [currentUser, customerForm])

    useEffect(() => {
        if (loginConfirmationCode && loginConfirmationCode.length == 4) {
            setSubmittable(true)
            setLoginCodeAcceptable(true)
        } else {
            setSubmittable(false)
            setLoginCodeAcceptable(false)
        }
    }, [loginConfirmationCode])

    const showToasterMessage = (content, level, dismiss) => {
        message.open({ type: level, content: content, duration: (dismiss ? 5 : 0) })
    }

    const countdownRenderer = (opts) => {
        if (!opts.completed) {
            return <span className={shoppingCartStyles.offlinePaymentNotice}>(you can try again in {opts.formatted.seconds}...)</span>;
        }
    };

    const getPhoneConfirmationCodeHandler = (evt) => {
        evt.preventDefault();

        setCodeButtonEnabled(false);

        try {
            fetch(`/api/v1/customers/request_code.json`, {
                method: 'POST',
                body: JSON.stringify({ customer: { phone_number: loginPhoneNumber } }),
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.result === "failure") {
                        showToasterMessage(data.object.message, 'error', true)

                        setCodeButtonEnabled(true);
                        setCodeHasBeenSent(false);
                        setCodeButtonTimeout(null);
                        setCodeButtonText("Send code");

                        Sentry.captureMessage("Failure trying to get customer login code")

                        return false;
                    } else if (data.result === "success") {
                        setCodeHasBeenSent(true)
                        setCodeButtonTimeout(new Date().getTime() + CODE_SENT_RESET_SECONDS * 1000);
                        setCodeButtonText(`Code Sent`)

                        if (authCodeRef.current) {
                            authCodeRef.current.focus({
                                cursor: 'all',
                            })
                        }

                        setTimeout(function () {
                            setCodeButtonEnabled(true);
                            setCodeHasBeenSent(false);
                            setCodeButtonTimeout(null);
                            setCodeButtonText("Send code");
                        }, CODE_SENT_RESET_SECONDS * 1000);

                        // showToasterMessage(data.message, 'success', true)

                        return true;
                    }
                })
                .catch(err => {
                    Sentry.captureException(err)

                    setCodeButtonEnabled(true);
                    setCodeHasBeenSent(false);
                    setCodeButtonTimeout(null);
                    setCodeButtonText("Send code");

                    showToasterMessage("Oops. The message couldn't be sent", 'error', true)

                    return false;
                });
        } catch (error) {
            Sentry.captureException(error)

            showToasterMessage("Oops. The message couldn't be sent", 'error', true)

            return false;
        }
    };

    const handleUserLogin = evt => {
        if (!submittable) {
            showToasterMessage("Phone number is wrong, it seems", 'warning', true)

            return;
        }

        if (!loginPhoneNumber) {
            showToasterMessage("We need your phone number and a code.", 'warning', true)

            return;
        }

        if (!loginConfirmationCode) {
            showToasterMessage("Request a confirmation code and use it below.", 'warning', true)

            return;
        }

        try {
            fetch(`/api/v1/customers/validate_code.json`, {
                method: 'POST',
                body: JSON.stringify({
                    customer: {
                        phone_number: loginPhoneNumber,
                        phone_verification_code: loginConfirmationCode
                    }
                }),
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.result == 'failure') {
                        showToasterMessage("Oops. Either it's a wrong code or we failed to check it", 'error', true)

                        Sentry.captureMessage("Failure trying to get customer login code")

                        setCodeButtonEnabled(true);
                        setCodeButtonText("Send code")
                        setLoginConfirmationCode("")

                        return false;
                    } else {
                        if (data.customer) {
                            const customer = data.customer

                            setCurrentUser(customer);

                            customerForm.setFieldsValue({
                                name: customer.name,
                                email: customer.email,
                                phone_number: customer.phone_number
                            });

                            setCustomerDetails({ ...customerDetails, name: customer.name, phone_number: customer.phone_number });

                            setCodeButtonEnabled(true);
                            setCodeButtonText("Send code")
                            setLoginConfirmationCode("")
                        } else {
                            setCurrentUser(null);

                            showToasterMessage("Oops. Either it's a wrong code or we failed to check it", 'error', true)

                            setCodeButtonEnabled(true);
                            setCodeButtonText("Send code")
                            setLoginConfirmationCode("")

                            return false;
                        }
                    }
                })
                .catch(err => {
                    Sentry.captureException(err);

                    showToasterMessage("Oops. Either it's a wrong code or we failed to check it", 'error', true)

                    setCodeButtonEnabled(true);
                    setCodeButtonText("Send code")
                    setLoginConfirmationCode("")

                    return false;
                })
        } catch (error) {
            Sentry.captureException(error);

            showToasterMessage("Oops. Either it's a wrong code or we failed to check it", 'error', true)

            setCodeButtonEnabled(true);
            setCodeButtonText("Send code")
            setLoginConfirmationCode("")

            return false;
        }
    };

    const clearUser = () => {
        setCurrentUser(null)
        localStorage.removeItem('currentUserJSON');

        hideModal()
    };

    const onCustomerDetailsFinish = (values) => {
        let mergedValues = { ...customerDetails }
        let newValues = { ...values }

        const newPhoneNumber = newValues.phone_number

        if (newPhoneNumber && newPhoneNumber.valid && newPhoneNumber.valid()) {
            newValues = { ...newValues, phone_number: fullPhoneNumber(newPhoneNumber) }
        } else {
            newValues = { ...newValues, phone_number: currentUser.phone_number }
        }

        mergedValues = { ...mergedValues, ...newValues }

        // setCustomerDetails(mergedValues);
        setCurrentUser({ ...currentUser, ...mergedValues });

        hideModal()
    };

    const fullPhoneNumber = (numberObject) => {
        return `+${numberObject.countryCode}${numberObject.areaCode}${numberObject.phoneNumber}`
    }

    const phoneNumberValidator = (evt, obj) => {
        // if (valid(true)) return Promise.resolve(); // strict validation
        if ((obj.valid && obj.valid()) || (typeof (obj) == "string" && obj != "")) {
            setPhoneNumberValid(true);
            setCodeButtonEnabled(true);
            return Promise.resolve(); // non-strict validation
        } else {
            setPhoneNumberValid(false);
            setCodeButtonEnabled(false);
            return Promise.reject("Invalid phone number");
        }
    }

    return (<Flex gap={24}>
        <Flex justify='center' style={{ flexGrow: 1 }}> {currentUser ?
            <Button className={shoppingCartStyles.foodridersPillButton} type="primary" onClick={showModal}>
                Hola {customerDetails.name || "rider"}!
            </Button> : <Button className={shoppingCartStyles.foodridersPillButton} type='primary' onClick={showModal}>Log In</Button>
        }</Flex>

        <Modal title="Login to keep your info" footer={null} open={modalOpen} onOk={handleUserLogin} onCancel={hideModal}>
            <Flex vertical gap="large">
                <Text type='primary' >
                    Join the Die Hard Club for access to exclusives and more in the future. You can check our Privacy Policy <a href='https://foodriders.co/fine-print' target='_blank'>here</a>.
                </Text>

                {currentUser ? <>
                    <Form
                        layout='vertical'
                        name='customerForm'
                        form={customerForm}
                        // onValuesChange={customerFormChangeHandler}
                        onFinish={onCustomerDetailsFinish} >
                        <Form.Item
                            name='name'
                            label="Name"
                            initialValue={currentUser.name}
                            rules={[{ required: true, min: 2, type: 'string', whitespace: true, message: "Name is required" }]}>

                            <Input placeholder='Your Name' />
                        </Form.Item>

                        <Form.Item
                            name='phone_number'
                            style={{ flexGrow: 1 }}>

                            <PhoneInput
                                disabled
                                enableArrow
                                enableSearch
                                disableParentheses
                                value={currentUser.phone_number}
                                // onChange={phoneNumberChangeHandler}
                                country="pt"
                                inputMode='tel'
                                autoComplete='off'
                                type="tel" />
                        </Form.Item>

                        <Form.Item
                            name='email'
                            initialValue={customerDetails.email}
                            rules={[{ required: false, min: 2, type: 'email', whitespace: true, message: "Email isn't valid" }]}>

                            <Input type="email" inputMode='email' placeholder='Your Email' autoComplete="email" autoCorrect='false' autoCapitalize='off' spellCheck="false" />
                        </Form.Item>

                        <Flex gap="small" justify='space-between'>
                            <Button type='primary' onClick={clearUser}>Logout</Button>

                            <Button type='primary' htmlType='submit'>Save</Button>
                        </Flex>
                    </Form>
                </> : <Form
                    layout='vertical'
                    name='loginForm'
                    form={loginForm}
                    onValuesChange={(changedVals, allVals) => { console.log("setting phone ", changedVals, allVals); }}
                // onFinish={onLoginFormFinish}
                >
                    <Flex vertical gap={12}>
                        <Form.Item
                            // initialValue=""
                            name='phone_number'
                            rules={[{ required: true, message: "Phone Number is invalid", validator: phoneNumberValidator }]}
                            style={{ flexGrow: 1 }}>

                            <Suspense fallback={<p>Loading</p>}>
                                <IntlTelInput
                                    initialValue={loginPhoneNumber}
                                    onChangeNumber={(val) => { console.log("setting phone ", val); setLoginPhoneNumber(val) }}
                                    onChangeValidity={(valid) => { setCodeButtonEnabled(valid); setPhoneNumberValid(valid) }}
                                    // onChangeErrorCode={setErrorCode}
                                    // any initialisation options from the readme will work here
                                    initOptions={{
                                        initialCountry: "pt",
                                        strictMode: true,
                                        containerClass: "intl-tel-input-zIndex-1500",
                                    }}
                                />
                            </Suspense>


                        </Form.Item>

                        <Button type='primary' size="large" disabled={!codeButtonEnabled} loading={codeHasBeenSent} onClick={getPhoneConfirmationCodeHandler}>
                            {codeButtonText}
                        </Button>

                        {codeHasBeenSent && <Countdown zeroPadTime={0} renderer={countdownRenderer} date={codeButtonTimeout} />}

                        <Form.Item
                            initialValue=""
                            name='phone_verification_code'
                            className={styles.phoneVerificationCodeField}
                            style={{ textAlign: 'center', marginTop: '3rem' }}>

                            <ConfigProvider
                                theme={{
                                    token: {
                                        fontSize: "2rem",
                                        colorTextPlaceholder: "#aaa",
                                    },
                                }}>

                                <Input
                                    size='large'
                                    maxLength={4}
                                    ref={authCodeRef}
                                    inputMode='numeric'
                                    placeholder='••••'
                                    className={styles.phoneConfirmationCode}
                                    // autoComplete="off"
                                    value={loginConfirmationCode}
                                    onChange={(evt) => { setLoginConfirmationCode(evt.target.value) }}
                                />
                            </ConfigProvider>
                        </Form.Item>

                        <Button type='primary' size="large" onClick={handleUserLogin} disabled={!loginCodeAcceptable}>Confirm</Button>
                    </Flex>
                </Form>}
            </Flex>
        </Modal>
    </Flex>)
}

export default LoginWidget;