'use client';

import React, { lazy, Suspense } from 'react';

import { Flex } from 'antd';

import OrderDetails from './OrderDetails';

// const ShoppingCart = lazy(() => import('./ShoppingCart'));

import ShoppingCart from './ShoppingCart';

// import LoadingSpinner from '../../shared/LoadingSpinner';

import * as styles from './ShoppingCart.module.scss'

const FinalizeOrder = () => {
  return (
    <Flex vertical gap={24} className={styles.finalizeOrder}>
      {/* <Suspense fallback={<LoadingSpinner tip="Loading cart" />}> */}
      <ShoppingCart />
      {/* </Suspense> */}

      {/* <Suspense fallback={<LoadingSpinner />}> */}
      <OrderDetails />
      {/* </Suspense> */}
    </Flex>
  );
};

export default FinalizeOrder;
