import React, { useState } from 'react'
import { Link } from "react-router-dom"

import * as styles from './Landing.module.scss'

const ConceptLink = ({ concept }) => {
    const [showingDetails, setShowingDetails] = useState(false)

    return (
        <div className={styles.conceptEntry}>
            <div className={styles.conceptHead}>
                <div className={styles.routeNumber} onClick={() => { setShowingDetails(isShowing => !isShowing) }}>{`Route ${concept.route_number.toString().padStart(2, '0')}`}</div>

                <div className={styles.conceptName}>
                    <Link className={styles.conceptTitle} to={`/menus/${concept.slug}`}>{concept.name}</Link>
                </div>
            </div>

            <div className={styles.conceptDescription} onClick={() => { setShowingDetails(isShowing => !isShowing) }}>
                {concept.description}

                {showingDetails &&
                    <div>
                        <br />
                        {concept.working_hours}
                        <br />
                        Delivery: {concept.delivery_time}
                        <br />
                        {concept.address}
                    </div>
                }
            </div>


        </div>
    )
}

export default ConceptLink
