import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';

import * as Sentry from "@sentry/react";

import { Helmet } from "react-helmet";

import MenuSectionsRoot from './components/MenuSectionsRoot';
import LoadingSpinner from '../shared/LoadingSpinner';

import CartProvider from 'contexts/CartContext';
import { MenuContext } from '../../contexts/MenuContext';

const Menu = (props) => {
    const { menu } = useContext(MenuContext);

    const pageTitle = () => {
        if (menu?.name) {
            return `FoodRiders - ${menu?.name}`
        } else {
            return "FoodRiders"
        }
    }

    return (<>
        <Helmet>
            <title>{pageTitle()}</title>
        </Helmet>

        <CartProvider>
            <Suspense fallback={<LoadingSpinner />}>
                <MenuSectionsRoot />
            </Suspense>
        </CartProvider>
    </>);
};

export default Sentry.withProfiler(Menu);
