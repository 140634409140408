import React from 'react'

import { Flex } from 'antd';

import * as shoppingCartStyles from '../components/ShoppingCart.module.scss';

const FooterBar = () => {
    return (
        <Flex className={shoppingCartStyles.footerBar}>
            Powered by Foodriders
        </Flex>
    )
}

export default FooterBar