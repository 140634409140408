import React, { useState, lazy, Suspense, useEffect, useContext } from 'react'
import { Empty, Flex } from 'antd';

import * as Sentry from '@sentry/react';

import ItemCard from './ItemCard';

import { CartContext } from '../../../contexts/CartContext';

const Section = ({ section }) => {
    const cart = useContext(CartContext);

    const [items, setItems] = useState(section.menu_items || [])
    const [filteredItems, setFilteredItems] = useState([])

    useEffect(() => {
        if (items.length) {
            if (cart.fulfilmentType) {
                setFilteredItems(items.filter(item => (item.availability_status > 0 && item.available_in.length > 0 && item.available_in.includes(cart.fulfilmentType))))
            } else {
                setFilteredItems(items.filter(item => (item.availability_status > 0 && item.available_in.length > 0)))
            }
        }
    }, [cart.fulfilmentType, items])

    useEffect(() => {
        // console.log('fetching section', section.id)
        fetch(`/api/v1/sections/${section.id}.json?template=minimal`)
            .then(response => response.json())
            .then(data => {
                setItems(data.menu_section.menu_items);
            })
            .catch(err => {
                Sentry.captureException(err)
            })
    }, []);

    return (<Flex vertical gap={24}>
        <Flex gap={24} wrap justify='center'>
            {filteredItems ? filteredItems.map(item => (
                <ItemCard key={item.id} item={item} />
            )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Loading Menu..." />}
        </Flex>
    </Flex>)
}

export default Section
