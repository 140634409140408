import React, { useEffect, useState, useCallback, useContext } from 'react';

import { Flex, Statistic, Checkbox, Select, Divider, ConfigProvider } from 'antd';

import AddToCart from './AddToCart';

import { CartContext } from 'contexts/CartContext';
import { MenuContext } from 'contexts/MenuContext';

import * as menuStyles from '../Menu.module.scss';

const ItemModifiers = ({ item, canOrder = true }) => {
    const cart = useContext(CartContext);
    const { menu } = useContext(MenuContext);

    const [allVariantOptions, setAllVariantOptions] = useState([]);
    const [filteredVariantGroups, setFilteredVariantGroups] = useState([]);
    const [multipleVariantGroups, setMultipleVariantGroups] = useState([]);
    const [singleVariantGroups, setSingleVariantGroups] = useState([]);

    const [variantsSelection, setVariantsSelection] = useState({});

    useEffect(() => {
        if (item.variant_groups.length > 0) {
            const localFilteredGroups = item.variant_groups.filter(group => group.available_in.includes(cart.fulfilmentType))

            if (localFilteredGroups.length > 0) {
                setFilteredVariantGroups(localFilteredGroups)

                const localOptions = localFilteredGroups.map(group => group.options).flat(Infinity)

                if (localOptions.length > 0) {
                    setAllVariantOptions(localOptions)
                }

                const singleVars = localFilteredGroups.filter(group => !group.multiple)
                const multipleVars = localFilteredGroups.filter(group => group.multiple)

                setMultipleVariantGroups(multipleVars)
                setSingleVariantGroups(singleVars)
            }
        }
    }, [item.variant_groups]);

    const handleVariantChange = (evt) => {
        const { name, checked } = evt.target;

        let newVariants = { ...variantsSelection }

        if (checked) {
            newVariants = { ...newVariants, [name]: name }
        } else {
            delete newVariants[name];
        }

        setVariantsSelection(newVariants);
    };

    const variationSelectionHandler = (value) => {
        const values = value.split(".")

        setVariantsSelection({ ...variantsSelection, [values[1]]: values[0] })
    }

    const eurifiedPrice = (price) => {
        return price.toLocaleString('pt-PT', {
            style: 'currency',
            currency: 'EUR'
        });
    }

    const eurifiedPriceForContext = (itemOrVariant) => {
        const euroPrice = euroPriceForContext(itemOrVariant);

        return eurifiedPrice(euroPrice);
    }

    const nameWithPriceForOption = (itemOrVariant) => {
        const euroPrice = euroPriceForContext(itemOrVariant);

        if (euroPrice > 0) {
            if (itemOrVariant.is_addon) {
                return `${itemOrVariant.name} (+ ${eurifiedPrice(euroPrice)})`
            } else {
                return `${itemOrVariant.name} (. ${eurifiedPrice(euroPrice)})`
            }
        } else {
            return itemOrVariant.name;
        }
    }

    const euroPriceForContext = (itemOrVariant) => {
        const centsPrice = centsPriceForContext(itemOrVariant);

        return centsPrice / 100;
    }

    const centsPriceForContext = (itemOrVariant) => {
        let selected_price;

        switch (cart.fulfilmentType) {
            case 'delivery':
                selected_price = itemOrVariant.prices['delivery'] || itemOrVariant.prices['takeaway'] || itemOrVariant.prices['tableservice'] || itemOrVariant.prices['default'];
                break;
            case 'takeaway':
                selected_price = itemOrVariant.prices['takeaway'] || itemOrVariant.prices['delivery'] || itemOrVariant.prices['tableservice'] || itemOrVariant.prices['default'];
                break;
            case 'tableservice':
                selected_price = itemOrVariant.prices['tableservice'] || itemOrVariant.prices['takeaway'] || itemOrVariant.prices['delivery'] || itemOrVariant.prices['default'];
                break;
            default:
                selected_price = itemOrVariant.prices['tableservice'] || itemOrVariant.prices['takeaway'] || itemOrVariant.prices['delivery'] || itemOrVariant.prices['default'];
                break;
        }

        if (selected_price) {
            return selected_price.total || 0;
        } else {
            return itemOrVariant.price || 0;
        }
    }

    const canAddToCartCallback = useCallback(() => {
        const requiredGroupsIds = filteredVariantGroups.filter(group => group.required).map(group => group.id)

        return (canOrder && (!hasOptions() || !requiredGroupsIds.map(i => variantsSelection[i]).includes(undefined)))
    }, [variantsSelection, filteredVariantGroups])

    const hasOptions = () => {
        // if false = all variants are modifiers = return true
        // if true = some variants are add-ons = return false
        return item.variant_groups.filter(section => section.available_in.includes(cart.fulfilmentType)).map(el => el.required).includes(true)
    }

    const hasContextPrices = () => {
        return (item.prices != null)
    }

    const itemVariationsPriceRange = () => {
        let variantPriceRange = allVariantOptions.map(variant => eurifiedPriceForContext(variant))
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort()
            .join('-').trim();
        // .filter(variant => variant.variant_type)
        // return `${JSON.stringify(itemVariants)}`
        return (variantPriceRange || "err").replace(/ /g, '\u00a0');
    };

    return (
        <Flex vertical gap={24}>
            {filteredVariantGroups.length > 0 && <Flex vertical gap={12}>

                <Divider plain>Make it your way</Divider>

                <Flex vertical>

                    {singleVariantGroups.map(group => <Select
                        onChange={variationSelectionHandler}
                        placeholder={group.name}
                        key={group.id}
                        options={group.options.map(variant => ({
                            value: `${variant.id}.${group.id}`,
                            label: nameWithPriceForOption(variant)
                        }))} />)}

                </Flex>

                <Flex vertical>
                    {multipleVariantGroups.map(group => (group.options.map(option => <Checkbox
                        name={option.id}
                        key={group.id}
                        onChange={handleVariantChange}>{nameWithPriceForOption(option)}</Checkbox>)))}
                </Flex>
            </Flex>}


            <Flex justify='space-between'>
                <ConfigProvider
                    theme={{
                        token: {
                            colorText: (canOrder ? "black" : "#aaa")
                        },
                    }}
                >
                    <Statistic className={menuStyles.menuItemPrice} value={(item.prices ? eurifiedPriceForContext(item) : itemVariationsPriceRange())} />
                </ConfigProvider>

                {menu.ordering_enabled && <AddToCart
                    canAddToCart={canAddToCartCallback}
                    item={[item]}
                    disabled={!canOrder}
                    addOns={variantsSelection}
                    count={cart.numberOfItemsInCart(filteredVariantGroups.length > 0 ? `${item.id}.*` : [item.id].join('.'))}
                    size="large" />}
            </Flex>
        </Flex>)
}

export default ItemModifiers