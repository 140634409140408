import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';

import { Flex, Form, Modal, Input, Button, Space, Select, Collapse, Checkbox } from 'antd';

import * as shoppingCartStyles from './ShoppingCart.module.scss';

const InvoiceDetails = ({ currentUser, invoicingDetails, setInvoicingDetails }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const [nifNumberIsValid, setNifNumberIsValid] = useState(false);
    const [nifValidationMessage, setNifValidationMessage] = useState(null);
    const [selectedCountryId, setSelectedCountryId] = useState(null);
    const [needsVatInvoice, setNeedsVatInvoice] = useState(true);

    // const cart = useContext(CartContext);

    const [invoicingForm] = Form.useForm();

    const showModal = () => { setModalOpen(true) }
    const hideModal = () => { setModalOpen(false) }

    useEffect(() => {
        if (invoicingDetails) {
            invoicingForm.setFieldsValue(invoicingDetails)
        }
    }, [invoicingDetails])

    // const selectInvoicingDetails = evt => {
    //     setEditingInvoicingDetails(true);

    //     const selectedInvDeets = currentUser.invoice_details.find(
    //         el => el.id == evt.key
    //     );

    //     setInvoicingDetails({
    //         id: selectedInvDeets.id,
    //         name: selectedInvDeets.name,
    //         vat_number: selectedInvDeets.vat_number,
    //         address: selectedInvDeets.address
    //     });

    //     invoicingForm.setFieldsValue(selectedInvDeets);
    // };

    // const addNewInvoicingDetails = evt => {
    //     setEditingInvoicingDetails(true);

    //     setInvoicingDetails({
    //         name: '',
    //         vat_number: '',
    //         address: ''
    //     });

    //     invoicingForm.setFieldsValue({
    //         name: '',
    //         vat_number: '',
    //         address: ''
    //     });
    // };

    // const invoiceProfileChangeHandler = () => {

    // }

    const closeWithoutSaving = () => {
        // invoicingForm.setFieldsValue(invoicingDetails)

        hideModal()
    }

    const invoicingFormFinish = (values) => {
        console.log("invoicing form finish", needsVatInvoice, values);
        if (!needsVatInvoice) {
            invoicingForm.setFieldsValue({
                vat_number: "",
                name: "",
                phone_number: "",
                email_address: "",
                address_line1: "",
                address_line2: "",
                postcode: "",
                city: "",
                country_id: "1"
            })
            setInvoicingDetails({
                vat_number: "",
                name: "",
                phone_number: "",
                email_address: "",
                address_line1: "",
                address_line2: "",
                postcode: "",
                city: "",
                country_id: "1"
            })
        } else {
            setInvoicingDetails(values)
        }

        hideModal()
    }

    const invoicingDetailsSet = () => {
        return (invoicingDetails?.vat_number != "")
    }

    const validatePostcode = (event, obj) => {
        if (invoicingForm.getFieldValue('country_id') != "1" || !needsVatInvoice) {
            return Promise.resolve();
        }

        if ((obj.valid && obj.valid()) || typeof (obj) == "string") {
            if (obj == "" || obj.match(/\d{4}\-\d{3}/)) {
                return Promise.resolve();
            } else {
                return Promise.reject("Portuguese addresses require valid postcodes XXXX-YYY");
            }
        } else {
            return Promise.reject("Invalid postcode");
        }
    }

    const validateNIF = (event, obj) => {
        if (!needsVatInvoice) {
            setNifNumberIsValid(true);
            // setNifValidationMessage(null);
            return Promise.resolve();
        }

        if (typeof (obj) != "string") {
            return Promise.reject("Invalid VAT mumber");
        }

        if (invoicingForm.getFieldValue('country_id') != "1") {
            setNifNumberIsValid(true);
            // setNifValidationMessage(null);
            return Promise.resolve();
        }

        if ((obj.valid && obj.valid()) || (typeof (obj) == "string")) {
            const value = obj;

            let sumAux = 0;
            for (let i = 9; i >= 2; i--) {
                sumAux += i * (parseInt(value[value.length - i]) || 0);
            }

            const module = sumAux % 11;

            // Get the eight first numbers
            const NIFwithoutLastDigit = value.slice(0, value.length - 1);

            let isNifValid = false;

            if (module === 0 || module === 1) {
                isNifValid = (`${NIFwithoutLastDigit}0` === value);
            } else {
                isNifValid = (`${NIFwithoutLastDigit}${11 - module}` === value);
            }

            setNifNumberIsValid(isNifValid);

            if (isNifValid) {
                // setNifValidationMessage("Number is a valid portuguese NIF");
                // setNifValidationMessage("Number is a valid portuguese NIF");
                return Promise.resolve(); // non-strict validation
            } else {
                // setNifValidationMessage("Not a valid portuguese NIF");
                return Promise.reject("Not a valid portuguese NIF (123456789)");
            }
        } else {
            setNifNumberIsValid(false);
            // setNifValidationMessage("Invalid NIF");
            return Promise.reject("Invalid NIF");
        }

        // console.log("validating nif", event.target.value);
        // const value = event.target.value;

        // if (!value || typeof value !== "string") {
        //     return false;
        // }

        // if (value.length !== 9) {
        //     setNifNumberIsValid(false);
        //     setNifValidationMessage("Not a valid portuguese NIF");
        //     return false;
        // }

        // let sumAux = 0;
        // for (let i = 9; i >= 2; i--) {
        //     sumAux += i * (parseInt(value[value.length - i]) || 0);
        // }

        // const module = sumAux % 11;

        // // Get the eight first numbers
        // const NIFwithoutLastDigit = value.slice(0, value.length - 1);

        // let isNifValid = false;

        // if (module === 0 || module === 1) {
        //     isNifValid = (`${NIFwithoutLastDigit}0` === value);
        // } else {
        //     isNifValid = (`${NIFwithoutLastDigit}${11 - module}` === value);
        // }

        // setNifNumberIsValid(isNifValid);

        // if (isNifValid) {
        //     setNifValidationMessage("Number is a valid portuguese NIF");
        // } else {
        //     setNifValidationMessage("Not a valid portuguese NIF");
        // }

        // return isNifValid;
    };

    const invoicingFormChangeHandler = (changedValues, allValues) => {
        if (changedValues.country_id) {
            // setSelectedCountryId(changedValues.country_id);
            // invoicingForm.setFieldValue('country', "Portugal");
            invoicingForm.validateFields(['postcode', "vat_number"]);
        }
    }

    const invoiceOptionChanged = (value) => {
        setNeedsVatInvoice(value == "with_vat");
    }

    return (<>
        <Flex>
            {!modalOpen && <Button className={shoppingCartStyles.foodridersPillButton} type='primary' onClick={showModal}>
                {invoicingDetailsSet() ? `NIF (${[invoicingDetails.vat_number, invoicingDetails.name].filter(el => el != "").join(' / ')})` : "Invoice"}
            </Button>}
        </Flex>

        <Modal
            title="Receipt/Invoice"
            open={modalOpen}
            onCancel={hideModal}
            footer={null}>

            <Form
                layout='vertical'
                name='invoicingForm'
                form={invoicingForm}
                onValuesChange={invoicingFormChangeHandler}
                onFinish={invoicingFormFinish}
                initialValues={{
                    name: currentUser?.name || ""
                }} >

                <Flex vertical gap="small">

                    <Collapse onChange={invoiceOptionChanged} defaultActiveKey="with_vat" accordion items={[
                        {
                            key: 'without_vat',
                            label: <strong>If a receipt is enough</strong>,
                            children: <Flex vertical gap="small">
                                <p>We don't need any extra details. You'll get a link to your receipt after the order is accepted, paid and fulfilled.</p>
                            </Flex>
                        },
                        {
                            key: 'with_vat',
                            label: <strong>If you need a VAT invoice</strong>,
                            children: <Flex vertical gap="small">
                                {(currentUser?.invoicing_contacts?.length > 0 && <Select
                                    onChange={invoiceProfileChangeHandler}
                                    placeholder="Pick one"
                                    options={currentUser.invoicing_contacts.map((el) => ({ value: el.uuid, label: el.name }))} />)}

                                <Flex gap="small">
                                    <Form.Item label='Name' name='name' style={{ flexGrow: 1, marginBottom: '1rem' }}>
                                        <Input placeholder="name" />
                                    </Form.Item>

                                    <Form.Item
                                        label='NIF/VAT Number'
                                        name='vat_number'
                                        // onChange={validateNIF}
                                        validateDebounce={500}
                                        rules={[{ required: true, validator: validateNIF }]}
                                        style={{ flexGrow: 1, marginBottom: '1rem' }}>

                                        <Input placeholder="nif" />
                                    </Form.Item>
                                </Flex>
                                {/* 
                            <Flex gap="middle">
                                {nifValidationMessage && <Alert message={nifValidationMessage} type={nifNumberIsValid ? "success" : "info"} />}
                            </Flex> */}

                                <Flex gap="small">
                                    <Form.Item label='Phone Number' name='phone_number' rules={[{ required: false }]} style={{ flexGrow: 1, marginBottom: '1rem' }}>
                                        <Input placeholder="+351 900 000 000" />
                                    </Form.Item>

                                    <Form.Item label='Email Address' name='email_address' rules={[{ required: false }]} style={{ flexGrow: 1, marginBottom: '1rem' }}>
                                        <Input placeholder="hey@example.com" />
                                    </Form.Item>
                                </Flex>

                                <Flex vertical gap="0">
                                    <Form.Item label='Address' name='address_line1' rules={[{ required: false }]} style={{ flexGrow: 1, marginBottom: '0.5rem' }}>
                                        <Input placeholder="line 1" />
                                    </Form.Item>

                                    <Form.Item name='address_line2' rules={[{ required: false }]} style={{ flexGrow: 1, marginBottom: '0.5rem' }}>
                                        <Input placeholder='line 2' />
                                    </Form.Item>

                                    <Flex gap="small">
                                        <Form.Item style={{ flexGrow: 1 }} name='postcode' rules={[{ required: false, validator: validatePostcode }]}>

                                            <Input placeholder="Postcode" />
                                        </Form.Item>

                                        <Form.Item style={{ flexGrow: 1 }} name='city' rules={[{ required: false }]}>
                                            <Input placeholder="City" />
                                        </Form.Item>
                                    </Flex>

                                    <Flex gap="0" style={{ flexGrow: 1 }}>
                                        {/* <Form.Item hidden={true} label='Country' name='country' rules={[{ required: false }]}>
                                        <Input hidden={true} placeholder="Country" value="Portugal" />
                                    </Form.Item> */}

                                        <Form.Item style={{ width: "100%", flexGrow: 1, marginBottom: '0rem' }} label='Country' name='country_id' rules={[{ required: true }]} >
                                            <Select defaultValue={"1"} placeholder="Country">
                                                <Select.OptGroup label="most selected">
                                                    <Select.Option value="1">Portugal</Select.Option>
                                                    <Select.Option value='140'>México</Select.Option>
                                                    <Select.Option value='6'>Alemanha</Select.Option>
                                                    <Select.Option value='71'>USA</Select.Option>
                                                </Select.OptGroup>
                                                <Select.OptGroup label="others">
                                                    <Select.Option value='2'>Afeganistão</Select.Option>
                                                    <Select.Option value='3'>Africa do Sul</Select.Option>
                                                    <Select.Option value='4'>Alaska</Select.Option>
                                                    <Select.Option value='5'>Albânia</Select.Option>
                                                    <Select.Option value='7'>Andorra</Select.Option>
                                                    <Select.Option value='8'>Angola</Select.Option>
                                                    <Select.Option value='9'>Anguilla</Select.Option>
                                                    <Select.Option value='11'>Antilhas Holandesas</Select.Option>
                                                    <Select.Option value='10'>Antígua e Barbuda</Select.Option>
                                                    <Select.Option value='14'>Argentina</Select.Option>
                                                    <Select.Option value='13'>Argélia</Select.Option>
                                                    <Select.Option value='15'>Arménia</Select.Option>
                                                    <Select.Option value='16'>Aruba</Select.Option>
                                                    <Select.Option value='12'>Arábia Saudita</Select.Option>
                                                    <Select.Option value='17'>Ascensão</Select.Option>
                                                    <Select.Option value='18'>Austrália</Select.Option>
                                                    <Select.Option value='19'>Áustria</Select.Option>
                                                    <Select.Option value='20'>Azerbaijão</Select.Option>
                                                    <Select.Option value='21'>Bahamas</Select.Option>
                                                    <Select.Option value='22'>Bahrein</Select.Option>
                                                    <Select.Option value='23'>Bangladesh</Select.Option>
                                                    <Select.Option value='24'>Barbados</Select.Option>
                                                    <Select.Option value='26'>Belize</Select.Option>
                                                    <Select.Option value='27'>Benin</Select.Option>
                                                    <Select.Option value='28'>Bermudas</Select.Option>
                                                    <Select.Option value='29'>Bielorrussia</Select.Option>
                                                    <Select.Option value='30'>Bolívia</Select.Option>
                                                    <Select.Option value='32'>Botswana</Select.Option>
                                                    <Select.Option value='33'>Brasil</Select.Option>
                                                    <Select.Option value='34'>Brunei</Select.Option>
                                                    <Select.Option value='35'>Bulgária</Select.Option>
                                                    <Select.Option value='36'>Burkina Faso</Select.Option>
                                                    <Select.Option value='37'>Burundi</Select.Option>
                                                    <Select.Option value='38'>Butão</Select.Option>
                                                    <Select.Option value='25'>Bélgica</Select.Option>
                                                    <Select.Option value='31'>Bósnia-Herzegovina</Select.Option>
                                                    <Select.Option value='39'>Cabo Verde</Select.Option>
                                                    <Select.Option value='40'>Camarões</Select.Option>
                                                    <Select.Option value='41'>Camboja</Select.Option>
                                                    <Select.Option value='42'>Canadá</Select.Option>
                                                    <Select.Option value='43'>Carriacou</Select.Option>
                                                    <Select.Option value='45'>Cazaquistão</Select.Option>
                                                    <Select.Option value='46'>Chade</Select.Option>
                                                    <Select.Option value='47'>Chile</Select.Option>
                                                    <Select.Option value='48'>China</Select.Option>
                                                    <Select.Option value='49'>Chipre</Select.Option>
                                                    <Select.Option value='176'>Chéquia</Select.Option>
                                                    <Select.Option value='50'>Colombia</Select.Option>
                                                    <Select.Option value='51'>Comores</Select.Option>
                                                    <Select.Option value='52'>Congo</Select.Option>
                                                    <Select.Option value='54'>Coreia do Norte</Select.Option>
                                                    <Select.Option value='55'>Coreia do Sul</Select.Option>
                                                    <Select.Option value='57'>Costa Rica</Select.Option>
                                                    <Select.Option value='56'>Costa do Marfim</Select.Option>
                                                    <Select.Option value='58'>Croácia</Select.Option>
                                                    <Select.Option value='59'>Cuba</Select.Option>
                                                    <Select.Option value='239'>Curaçao</Select.Option>
                                                    <Select.Option value='61'>Dinamarca</Select.Option>
                                                    <Select.Option value='60'>Djibouti</Select.Option>
                                                    <Select.Option value='62'>Dominica</Select.Option>
                                                    <Select.Option value='64'>Egipto</Select.Option>
                                                    <Select.Option value='63'>El Salvador</Select.Option>
                                                    <Select.Option value='65'>Emirados Árabes Unidos</Select.Option>
                                                    <Select.Option value='66'>Equador</Select.Option>
                                                    <Select.Option value='67'>Eritreia</Select.Option>
                                                    <Select.Option value='68'>Eslováquia</Select.Option>
                                                    <Select.Option value='69'>Eslovénia</Select.Option>
                                                    <Select.Option value='70'>Espanha</Select.Option>
                                                    <Select.Option value='72'>Estónia</Select.Option>
                                                    <Select.Option value='73'>Etiópia</Select.Option>
                                                    <Select.Option value='241'>Federação de São Cristóvão e Nevis</Select.Option>
                                                    <Select.Option value='75'>Fiji</Select.Option>
                                                    <Select.Option value='76'>Filipinas</Select.Option>
                                                    <Select.Option value='77'>Finlândia</Select.Option>
                                                    <Select.Option value='78'>Formosa</Select.Option>
                                                    <Select.Option value='79'>França</Select.Option>
                                                    <Select.Option value='80'>Gabão</Select.Option>
                                                    <Select.Option value='82'>Gana</Select.Option>
                                                    <Select.Option value='83'>Geórgia</Select.Option>
                                                    <Select.Option value='84'>Gibraltar</Select.Option>
                                                    <Select.Option value='85'>Granada</Select.Option>
                                                    <Select.Option value='87'>Gronelândia</Select.Option>
                                                    <Select.Option value='86'>Grécia</Select.Option>
                                                    <Select.Option value='88'>Guadalupe</Select.Option>
                                                    <Select.Option value='89'>Guam</Select.Option>
                                                    <Select.Option value='90'>Guatemala</Select.Option>
                                                    <Select.Option value='240'>Guernsey</Select.Option>
                                                    <Select.Option value='91'>Guiana</Select.Option>
                                                    <Select.Option value='92'>Guiana Francesa</Select.Option>
                                                    <Select.Option value='93'>Guiné - Bissau</Select.Option>
                                                    <Select.Option value='95'>Guiné Equatorial</Select.Option>
                                                    <Select.Option value='94'>Guiné-Conacri</Select.Option>
                                                    <Select.Option value='81'>Gâmbia</Select.Option>
                                                    <Select.Option value='96'>Haiti</Select.Option>
                                                    <Select.Option value='97'>Hawai</Select.Option>
                                                    <Select.Option value='99'>Honduras</Select.Option>
                                                    <Select.Option value='100'>Hong Kong</Select.Option>
                                                    <Select.Option value='101'>Hungria</Select.Option>
                                                    <Select.Option value='238'>Ilha de Man</Select.Option>
                                                    <Select.Option value='254'>Ilhas Aland</Select.Option>
                                                    <Select.Option value='44'>Ilhas Caimão</Select.Option>
                                                    <Select.Option value='53'>Ilhas Cook</Select.Option>
                                                    <Select.Option value='74'>Ilhas Feroe</Select.Option>
                                                    <Select.Option value='135'>Ilhas Marshall</Select.Option>
                                                    <Select.Option value='188'>Ilhas Salomão</Select.Option>
                                                    <Select.Option value='227'>Ilhas Virgens Americanas</Select.Option>
                                                    <Select.Option value='232'>Ilhas Virgens Britânicas</Select.Option>
                                                    <Select.Option value='103'>India</Select.Option>
                                                    <Select.Option value='104'>Indonésia</Select.Option>
                                                    <Select.Option value='106'>Iraque</Select.Option>
                                                    <Select.Option value='107'>Irlanda</Select.Option>
                                                    <Select.Option value='108'>Irlanda do Norte</Select.Option>
                                                    <Select.Option value='105'>Irão</Select.Option>
                                                    <Select.Option value='109'>Islândia</Select.Option>
                                                    <Select.Option value='110'>Israel</Select.Option>
                                                    <Select.Option value='111'>Itália</Select.Option>
                                                    <Select.Option value='102'>Iémen</Select.Option>
                                                    <Select.Option value='112'>Jamaica</Select.Option>
                                                    <Select.Option value='113'>Japão</Select.Option>
                                                    <Select.Option value='236'>Jersey</Select.Option>
                                                    <Select.Option value='114'>Jordânia</Select.Option>
                                                    <Select.Option value='233'>Kosovo</Select.Option>
                                                    <Select.Option value='115'>Koweit</Select.Option>
                                                    <Select.Option value='116'>Laos</Select.Option>
                                                    <Select.Option value='117'>Lesoto</Select.Option>
                                                    <Select.Option value='118'>Letónia</Select.Option>
                                                    <Select.Option value='120'>Libéria</Select.Option>
                                                    <Select.Option value='122'>Liechtenstein</Select.Option>
                                                    <Select.Option value='123'>Lituânia</Select.Option>
                                                    <Select.Option value='124'>Luxemburgo</Select.Option>
                                                    <Select.Option value='119'>Líbano</Select.Option>
                                                    <Select.Option value='121'>Líbia</Select.Option>
                                                    <Select.Option value='125'>Macau</Select.Option>
                                                    <Select.Option value='126'>Macedónia</Select.Option>
                                                    <Select.Option value='127'>Madagáscar</Select.Option>
                                                    <Select.Option value='129'>Malawi</Select.Option>
                                                    <Select.Option value='130'>Maldivas</Select.Option>
                                                    <Select.Option value='131'>Mali</Select.Option>
                                                    <Select.Option value='132'>Malta</Select.Option>
                                                    <Select.Option value='133'>Malvinas</Select.Option>
                                                    <Select.Option value='128'>Malásia</Select.Option>
                                                    <Select.Option value='134'>Marrocos</Select.Option>
                                                    <Select.Option value='136'>Martinica</Select.Option>
                                                    <Select.Option value='137'>Mauritânia</Select.Option>
                                                    <Select.Option value='138'>Maurícias</Select.Option>
                                                    <Select.Option value='139'>Mayotte</Select.Option>
                                                    <Select.Option value='141'>Micronésia</Select.Option>
                                                    <Select.Option value='143'>Moldávia</Select.Option>
                                                    <Select.Option value='145'>Mongólia</Select.Option>
                                                    <Select.Option value='194'>Montenegro</Select.Option>
                                                    <Select.Option value='146'>Montserrat</Select.Option>
                                                    <Select.Option value='142'>Moçambique</Select.Option>
                                                    <Select.Option value='147'>Myanmar</Select.Option>

                                                    <Select.Option value='144'>Mónaco</Select.Option>
                                                    <Select.Option value='148'>Namíbia</Select.Option>
                                                    <Select.Option value='149'>Nauru</Select.Option>
                                                    <Select.Option value='150'>Nepal</Select.Option>
                                                    <Select.Option value='151'>Nicarágua</Select.Option>
                                                    <Select.Option value='152'>Niger</Select.Option>
                                                    <Select.Option value='153'>Nigéria</Select.Option>
                                                    <Select.Option value='154'>Niue</Select.Option>
                                                    <Select.Option value='155'>Norfolk</Select.Option>
                                                    <Select.Option value='156'>Noruega</Select.Option>
                                                    <Select.Option value='157'>Nova Caledónia</Select.Option>
                                                    <Select.Option value='158'>Nova Zelândia</Select.Option>
                                                    <Select.Option value='159'>Omã</Select.Option>
                                                    <Select.Option value='160'>Palau</Select.Option>
                                                    <Select.Option value='234'>Palestina</Select.Option>
                                                    <Select.Option value='161'>Panamá</Select.Option>
                                                    <Select.Option value='162'>Papua e Nova Guiné</Select.Option>
                                                    <Select.Option value='163'>Paquistão</Select.Option>
                                                    <Select.Option value='164'>Paraguai</Select.Option>
                                                    <Select.Option value='98'>Países Baixos</Select.Option>
                                                    <Select.Option value='165'>Peru</Select.Option>
                                                    <Select.Option value='166'>Pitcairn</Select.Option>
                                                    <Select.Option value='167'>Polinésia Francesa</Select.Option>
                                                    <Select.Option value='168'>Polónia</Select.Option>
                                                    <Select.Option value='169'>Porto Rico</Select.Option>
                                                    <Select.Option value='170'>Qatar</Select.Option>
                                                    <Select.Option value='171'>Quenia</Select.Option>
                                                    <Select.Option value='172'>Quirguistão</Select.Option>
                                                    <Select.Option value='173'>Quiribati</Select.Option>
                                                    <Select.Option value='174'>Reino Unido</Select.Option>
                                                    <Select.Option value='175'>Rep. Centro Africana</Select.Option>
                                                    <Select.Option value='177'>Rep. Dem. Congo</Select.Option>
                                                    <Select.Option value='178'>Rep.Dominicana</Select.Option>
                                                    <Select.Option value='179'>Reunião</Select.Option>
                                                    <Select.Option value='180'>Roménia</Select.Option>
                                                    <Select.Option value='181'>Ruanda</Select.Option>
                                                    <Select.Option value='182'>Rússia</Select.Option>
                                                    <Select.Option value='183'>S. Helena</Select.Option>
                                                    <Select.Option value='184'>S. Marino</Select.Option>
                                                    <Select.Option value='185'>S. Tomé e Príncipe</Select.Option>
                                                    <Select.Option value='186'>S. vicente e Grenadines</Select.Option>
                                                    <Select.Option value='189'>Samoa Americana</Select.Option>
                                                    <Select.Option value='190'>Samoa Ocidental</Select.Option>
                                                    <Select.Option value='251'>Santa Lucia</Select.Option>
                                                    <Select.Option value='192'>Senegal</Select.Option>
                                                    <Select.Option value='193'>Serra Leoa</Select.Option>
                                                    <Select.Option value='195'>Servia</Select.Option>
                                                    <Select.Option value='191'>Seychelles</Select.Option>
                                                    <Select.Option value='196'>Singapura</Select.Option>
                                                    <Select.Option value='198'>Somália</Select.Option>
                                                    <Select.Option value='199'>Sri Lanka</Select.Option>
                                                    <Select.Option value='200'>Suazilândia</Select.Option>
                                                    <Select.Option value='201'>Sudão</Select.Option>
                                                    <Select.Option value='204'>Suriname</Select.Option>
                                                    <Select.Option value='202'>Suécia</Select.Option>
                                                    <Select.Option value='203'>Suíça</Select.Option>
                                                    <Select.Option value='237'>São Bartolomeu</Select.Option>
                                                    <Select.Option value='252'>São Cristóvão e Neves</Select.Option>
                                                    <Select.Option value='259'>São Martinho (parte francesa)</Select.Option>
                                                    <Select.Option value='261'>São Martinho (parte neerlandesa)</Select.Option>
                                                    <Select.Option value='197'>Síria</Select.Option>
                                                    <Select.Option value='205'>Tailândia</Select.Option>
                                                    <Select.Option value='206'>Tajiquistão</Select.Option>
                                                    <Select.Option value='207'>Tanzânia</Select.Option>
                                                    <Select.Option value='208'>Timor Lorosae</Select.Option>
                                                    <Select.Option value='210'>Togo</Select.Option>
                                                    <Select.Option value='211'>Tokelau</Select.Option>
                                                    <Select.Option value='212'>Tonga</Select.Option>
                                                    <Select.Option value='209'>Trinidade e Tobago</Select.Option>
                                                    <Select.Option value='213'>Tristão da Cunha</Select.Option>
                                                    <Select.Option value='214'>Tunísia</Select.Option>
                                                    <Select.Option value='215'>Turks e Caicos</Select.Option>
                                                    <Select.Option value='216'>Turquemenistão</Select.Option>
                                                    <Select.Option value='217'>Turquia</Select.Option>
                                                    <Select.Option value='218'>Tuvalu</Select.Option>
                                                    <Select.Option value='219'>Ucrânia</Select.Option>
                                                    <Select.Option value='220'>Uganda</Select.Option>
                                                    <Select.Option value='221'>Uruguai</Select.Option>
                                                    <Select.Option value='222'>Uzbequistão</Select.Option>
                                                    <Select.Option value='223'>Vanuatu</Select.Option>
                                                    <Select.Option value='224'>Vaticano</Select.Option>
                                                    <Select.Option value='225'>Venezuela</Select.Option>
                                                    <Select.Option value='226'>Vietname</Select.Option>
                                                    <Select.Option value='229'>Wallis e Futuna</Select.Option>
                                                    <Select.Option value='231'>Zimbabwe</Select.Option>
                                                    <Select.Option value='230'>Zâmbia</Select.Option>
                                                </Select.OptGroup>
                                            </Select>
                                        </Form.Item>
                                    </Flex>

                                </Flex>

                            </Flex>,
                        }]} />
                    <Flex gap="small" justify='space-between' style={{ marginTop: '1rem' }}>
                        <Button type='primary' onClick={closeWithoutSaving}>Back</Button>

                        <Button type='primary' htmlType='submit'>Save</Button>
                    </Flex>
                </Flex>
            </Form>


        </Modal >
    </>);
}

export default InvoiceDetails;