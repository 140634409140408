import React, { useState, useContext, useEffect } from 'react';

import { Collapse, Select, Drawer, Segmented, Button, Flex, Space, ConfigProvider, Empty } from 'antd';

import { CartContext } from 'contexts/CartContext';
import { MenuContext } from 'contexts/MenuContext';
import { SessionContext } from 'contexts/SessionContext';

import classNames from 'classnames';

import Section from './Section';
import FinalizeOrder from './FinalizeOrder';
// const FinalizeOrder = lazy(() => import('./FinalizeOrder'));

import HeaderBar from '../components/HeaderBar';
import InfoSection from '../components/InfoSection';
// import LoadingSpinner from '../../shared/LoadingSpinner';
import FooterBar from '../parts/FooterBar'

// import bagIcon from 'images/icons/bag.png';
import lowriderIcon from 'images/menus/lowrider.svg';
import shoeboxIcon from 'images/menus/shoebox.svg';
import pacmanIcon from 'images/menus/pacman.svg';

import * as menuStyles from '../Menu.module.scss';
import * as shoppingCartStyles from './ShoppingCart.module.scss';

const MenuSectionsRoot = () => {
    const cart = useContext(CartContext);
    const { menu } = useContext(MenuContext);
    const session = useContext(SessionContext);

    const [activeKey, setActiveKey] = useState();

    // const [pickedDeliveryAddress, setPickedDeliveryAddress] = useState({});
    const [fulfilmentType, setFulfilmentType] = useState(); // 'delivery')
    const [initialSuggestedServiceType, setInitialSuggestedServiceType] = useState();
    // const [selectedSection, setSelectedSection] = useState(null);
    const [sections, setSections] = useState([]);
    // const [orderSize, setOrderSize] = useState(cart.getSize());

    const [cartOpen, setCartOpen] = useState(false)
    const showCart = () => { setCartOpen(true) }
    const hideCart = () => { setCartOpen(false) }

    useEffect(() => {
        let initialService;

        if (menu.tableservice_enabled) {
            initialService = 'tableservice';
        } else if (menu.delivery_enabled) {
            initialService = 'delivery';
        } else if (menu.takeaway_enabled) {
            initialService = 'takeaway';
        }

        setInitialSuggestedServiceType(initialService)
        setFulfilmentType(initialService)

        cart.initCart({
            initialMetadata: {
                deliveryFee: 5.35,
                fulfilmentType: initialService
            }
        }, session.shouldClearCart);

        if (session.shouldClearCart) {
            session.saveShouldClearCart(false);
        }

        // if (initialService != cart.fulfilmentType) {
        cart.setFulfilmentType(initialService)
        // }
    }, [menu]);

    useEffect(() => {
        if (activeKey) {
            // const theRef = getRef(activeKey)

            const activeSection = document.getElementById(`sec${activeKey}`)

            if (activeSection) {
                setTimeout(() => {
                    activeSection.scrollIntoView({ behavior: "instant", block: "start", inline: "nearest" })
                }, 150)
            }
        }
    }, [activeKey])

    useEffect(() => {
        if (menu?.menu_sections?.length > 0) {
            if (cart.fulfilmentType) {
                setSections(menu.menu_sections.filter(section => section.available_in.includes(cart.fulfilmentType)))
            } else {
                setSections(menu.menu_sections)
            }
        }
    }, [menu.menu_sections, cart.fulfilmentType])


    // const filteredSections = useMemo(()=>{
    //     return menu.menu_sections.filter(section => section.available_in.includes(fulfilmentType))
    // },[menu.menu_sections, fulfilmentType])

    useEffect(() => {
        const existingCartFulfilmentType = cart.fulfilmentType;

        switch (existingCartFulfilmentType) {
            case 'delivery':
                if (!menu.delivery_enabled) {
                    cart.setFulfilmentType(initialSuggestedServiceType)
                }
                break;
            case 'takeaway':
                if (!menu.takeaway_enabled) {
                    cart.setFulfilmentType(initialSuggestedServiceType)
                }
                break;
            case 'tableservice':
                if (!menu.tableservice_enabled) {
                    cart.setFulfilmentType(initialSuggestedServiceType)
                }
                break;
            default:
                break;
        }
        setFulfilmentType(existingCartFulfilmentType)
    }, [cart.fulfilmentType])

    // const humanReadablePickedAddress = () => {
    //     if (pickedDeliveryAddress) {
    //         if (pickedDeliveryAddress.place_name) {
    //             return pickedDeliveryAddress.place_name;
    //         } else if (pickedDeliveryAddress.text && pickedDeliveryAddress.address) {
    //             return `${pickedDeliveryAddress.text} ${pickedDeliveryAddress.address}`;
    //         } else {
    //             return "You'll be able to lookup your address at checkout. Now start by choosing food and drinks first.";
    //         }
    //     } else {
    //         return "You'll be able to lookup your address at checkout. Now start by choosing food and drinks first.";
    //     }
    // };

    // const collapseCallback = (key) => {
    //     if (key.length > 0) {
    //         setSelectedSection(key[0]);
    //     } else {
    //         setSelectedSection(null);
    //     }
    // }

    // const scrollSectionIntoView = (evt) => {
    //     const ref = getRef(evt)

    //     console.log("scrolling into ", evt, ref)

    //     getRef(evt).current.scrollIntoView({ behavior: "smooth", block: "start" })
    // }

    return (<>
        <div>
            <Flex vertical align='stretch' style={{ paddingBottom: "7rem" }}>
                <HeaderBar />
                <InfoSection />
                <Flex vertical align='center' justify='center' className={menuStyles.serviceTypeSelectorContainer}>
                    <Segmented size="large"
                        options={[
                            {
                                label: (<div className={classNames(menuStyles.serviceTypeSelectorItem, { [menuStyles.serviceTypeSelectorItemDisabled]: !menu.delivery_enabled, [menuStyles.serviceTypeSelectorItemSelected]: cart.fulfilmentType == "delivery" })}>
                                    <img className={menuStyles.serviceTypeSelectorItemIcon} src={lowriderIcon} />
                                    <div>Delivery</div>
                                </div>),
                                value: "delivery",
                                disabled: !menu.delivery_enabled
                            },
                            {
                                label: (<div className={classNames(menuStyles.serviceTypeSelectorItem, { [menuStyles.serviceTypeSelectorItemDisabled]: !menu.takeaway_enabled, [menuStyles.serviceTypeSelectorItemSelected]: cart.fulfilmentType == "takeaway" })}><img className={menuStyles.serviceTypeSelectorItemIcon} src={shoeboxIcon} /><div>Take-Away</div></div>),
                                value: "takeaway",
                                disabled: !menu.takeaway_enabled
                            },
                            {
                                label: (<div className={classNames(menuStyles.serviceTypeSelectorItem, { [menuStyles.serviceTypeSelectorItemDisabled]: !menu.tableservice_enabled, [menuStyles.serviceTypeSelectorItemSelected]: cart.fulfilmentType == "tableservice" })}><img className={menuStyles.serviceTypeSelectorItemIcon} src={pacmanIcon} /><div>Dine-In</div></div>),
                                value: "tableservice",
                                disabled: !menu.tableservice_enabled
                            }
                        ]}
                        value={cart.fulfilmentType}
                        onChange={(key) => { cart.setFulfilmentType(key) }} />
                </Flex>
                <Flex vertical className={menuStyles.menuContainer} align='stretch' justify='center'>
                    {sections.length > 0 ? <Collapse onChange={setActiveKey} accordion ghost size='large' items={sections.map(section => ({
                        label: <span className={menuStyles.menuSectionTitle}>{section.name}</span>,
                        className: menuStyles.menuSectionDivider,
                        key: `${menu.route_number}.${section.full_position_ids_with_menu}`,
                        id: `sec${menu.route_number}.${section.full_position_ids_with_menu}`,
                        children: <Section section={section} />,
                        forceRender: true
                    }))} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Loading Menu..." />}
                </Flex>
            </Flex>
            <Flex vertical justify='center' align="stretch" className={shoppingCartStyles.footerContainer}>
                <Flex vertical justify='center' align="stretch">
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    defaultHoverBg: 'default',
                                    defaultActiveBg: 'default',
                                    defaultActiveColor: 'white',
                                    defaultHoverColor: 'black'
                                }
                            }
                        }}>
                        <Button className={`${shoppingCartStyles.foodridersPillButton} ${shoppingCartStyles.checkoutNowButton} `} size="large" onClick={showCart}>Order Now ({cart.size})</Button>
                    </ConfigProvider>

                    <FooterBar />
                </Flex>
            </Flex>

        </div>


        <ConfigProvider drawer={{
            // classNames: {
            //     body: shoppingCartStyles.footerBarContainer
            // },
            styles: {
                body: {
                    padding: "0rem",
                    overscrollBehavior: 'contain'
                }
            }
        }}>

            <Drawer
                title={cart.size ? `Your Cart (${cart.size})` : "Cart is empty"}
                extra={<Space><Select size="small" defaultValue={cart.fulfilmentType} options={[
                    { value: 'tableservice', label: `Dine-in` },
                    { value: 'delivery', label: 'Delivery' },
                    { value: 'takeaway', label: 'Takeaway' }
                ]}
                    onChange={(value) => { cart.setFulfilmentType(value) }}
                    value={cart.fulfilmentType}
                    style={{
                        width: '8rem',
                    }} /></Space>}
                placement="bottom"
                closable={true}
                onClose={hideCart}
                open={cartOpen}
                height="80vh"
                size="large"
                className={shoppingCartStyles.cartDrawerContainer}
                key="cart-drawer">

                <Flex vertical >
                    {/* <Suspense fallback={<LoadingSpinner />}> */}
                    <FinalizeOrder />
                    {/* </Suspense> */}

                    <FooterBar />
                </Flex>
            </Drawer>

        </ConfigProvider>
    </>);
};

export default MenuSectionsRoot;