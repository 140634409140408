import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Order from "./Order";
import Menu from "./Menu";
import Landing from "./Landing";

import MenuContextProvider from "../contexts/MenuContext";
import SessionContextProvider from "../contexts/SessionContext";
import ConfigContextProvider from "../contexts/ConfigContext";
import LanguageContextProvider from "../contexts/LanguageContext";

import NotFound from "./Errors/NotFound";

import { App, ConfigProvider } from 'antd';

import('antd/dist/reset.css');

const OrderingRoot = (props) => {
  return (<BrowserRouter>
    <ConfigContextProvider>
      <SessionContextProvider>
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: "#aaa",
              colorPrimary: "#000"
            },
          }}>

          <Switch>
            <Route path="/menus" exact>
              <Landing {...props} />
            </Route>

            {/* <Route path="/welcome" exact>
        <WelcomeBack {...props} />
      </Route> */}

            <Route path="/menus/:slug" exact>
              <LanguageContextProvider>
                <MenuContextProvider initialMenus={props.menus} value={{ slug: props.slug, menu: props.menu }}>
                  <App>
                    <Menu />
                  </App>
                </MenuContextProvider>
              </LanguageContextProvider>
            </Route>

            {/* <Route path="/orders" exact>
          <OrderList {...props} />
        </Route> */}

            <Route path="/orders/:uuid" exact>
              <Order {...props} />
            </Route>

            <Redirect from="/" to="/menus" exact />

            <Route component={NotFound} />
          </Switch>
        </ConfigProvider>
      </SessionContextProvider>
    </ConfigContextProvider>
  </BrowserRouter>)
}

export default OrderingRoot;