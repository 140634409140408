'use client';

import React, { useState, useContext, useEffect, useRef, Suspense, lazy } from 'react';

import * as Sentry from '@sentry/react';

import { CartContext } from 'contexts/CartContext';
import { MenuContext } from 'contexts/MenuContext';
import { SessionContext } from 'contexts/SessionContext';
import { ConfigContext } from 'contexts/ConfigContext';

import DeliveryDetails from './DeliveryDetails';
import LoginWidget from '../../shared/LoginWidget';
import PaymentWidget from '../../shared/PaymentWidget';
import InvoiceDetails from './InvoiceDetails';

import LoadingSpinner from '../../shared/LoadingSpinner';

import * as styles from './ShoppingCart.module.scss';

import mbWayLogo from 'images/order/mbway.png'

import {
    App,
    Switch,
    Select,
    Form,
    Input,
    Button,
    Spin,
    Col,
    Space,
    Flex,
    ConfigProvider
} from 'antd';

// const FoodridersConfig = lazy(() => import('../../../src/FoodridersConfig'));

// import FoodridersConfig from '../../../src/FoodridersConfig';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
loadStripe.setLoadParameters({ advancedFraudSignals: false })

const OrderDetails = () => {
    const { message } = App.useApp();

    const cart = useContext(CartContext);
    const { menu } = useContext(MenuContext);
    const session = useContext(SessionContext);
    const config = useContext(ConfigContext);

    const promocodeInput = useRef(null);

    const [showSpinner, setShowSpinner] = useState(false);

    const [wantsCardPayment, setWantsCardPayment] = useState(false);
    const [isPaying, setIsPaying] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const [stripePromise, setStripePromise] = useState(null);
    const [stripeOptions, setStripeOptions] = useState({
        loader: 'always',
        mode: 'payment',
        currency: 'eur',
        setupFutureUsage: 'on_session',
        captureMethod: 'manual',
        amount: cart.getTotalInCents(),
        appearance: {
            theme: 'stripe',
            variables: {
                borderRadius: '22px'
            },
            rules: {
                '.Input': {
                    borderRadius: 'initial',
                },
                '.Block': {
                    borderRadius: 'initial',
                },
                '.Button': {
                    borderRadius: 'initial',
                },
                '.PickerItem': {
                    borderRadius: 'initial',
                }
            }
        }
    })

    const [checkoutLoading, setCheckoutLoading] = useState(false);

    const [detailsForm] = Form.useForm();

    const [invoicingDetails, setInvoicingDetails] = useState({
        vat_number: "",
        name: "",
        phone_number: "",
        email_address: "",
        address_line1: "",
        address_line2: "",
        postcode: "",
        city: "",
        country_id: "1"
    });

    const [paymentDetails, setPaymentDetails] = useState({
        method: '',
        mbway_number: ''
    });

    const [customerDetails, setCustomerDetails] = useState({
        name: cart.getThing('customerName'),
        phone_number: '',
        phone_verification_code: '',
        email: ''
    });

    const [addressDetails, setAddressDetails] = useState({
        street_name: '',
        street_number: '',
        floor: '',
        delivery_method: 'to_the_door',
        delivery_instructions: '',
        center: null,
        place_name: ''
    });

    const [orderDetails, setOrderDetails] = useState({
        scheduled_time: 'asap',
        special_instructions: '',
        include_napkins: false,
        include_cutlery: false,
        menu_id: menu.id,
        table_number: '',
        promocode: cart.promocode
    });

    useEffect(() => {
        console.log("OrderDetails mounted", config);
        const stripePromise = loadStripe(config.stripeKey);

        setStripePromise(stripePromise);

        try {
            const loggedInUser = localStorage.getItem("currentUserJSON");

            if (loggedInUser) {
                const foundUserData = JSON.parse(loggedInUser)

                setCurrentUser(foundUserData);

                debouncedUserFetch()
            }
        } catch (error) {
            Sentry.captureException(error);
        }

        const existingInvoicingDetails = cart.getThing('invoicingDetails')

        if (existingInvoicingDetails) {
            setInvoicingDetails(existingInvoicingDetails);
        }

        if (cart.promocode) {
            checkPromocode()
        }
    }, []);

    useEffect(() => {
        if (session?.tableNumber) {
            setOrderDetails({ ...orderDetails, table_number: session.tableNumber })
            detailsForm.setFieldsValue({ table_number: session.tableNumber })
        }
    }, [session.tableNumber]);

    useEffect(() => {
        if (customerDetails.table_number) {
            session.setTableNumber(customerDetails.table_number)
        }
    }, [customerDetails.table_number])

    useEffect(() => {
        if (currentUser) {
            const filteredCurrentUserDetails = (({ name, email, phone_number }) => ({ name, email, phone_number }))(currentUser);

            const newCustomerDetails = { ...customerDetails, ...filteredCurrentUserDetails }

            setCustomerDetails(newCustomerDetails);

            detailsForm.setFieldsValue({ customer_name: newCustomerDetails.name })
            setPaymentDetails({ ...paymentDetails, mbway_number: newCustomerDetails.phone_number })

            localStorage.setItem('currentUserJSON', JSON.stringify(currentUser));
        }
    }, [currentUser]);

    useEffect(() => {
        if (invoicingDetails && (invoicingDetails.name && invoicingDetails.name.length > 2) && (invoicingDetails.vat_number && invoicingDetails.vat_number.length > 2)) {
            const existingInvoicingDetails = cart.getThing('invoicingDetails')

            if (!existingInvoicingDetails || existingInvoicingDetails != invoicingDetails) {
                cart.setThing('invoicingDetails', invoicingDetails);
            }
        }
    }, [invoicingDetails]);

    useEffect(() => {
        setPaymentDetails({ ...paymentDetails, mbway_number: customerDetails.phone_number })
        detailsForm.setFieldsValue({ mbway_number: customerDetails.phone_number })
    }, [customerDetails.phone_number])

    useEffect(() => {
        const customerName = customerDetails.name

        if (customerName) {
            const existingName = cart.getThing('customerName')

            if (!existingName || customerName != existingName) {
                cart.setThing('customerName', customerName);
            }

            detailsForm.setFieldsValue({ customer_name: customerName })
        }
    }, [customerDetails.name])

    const showToasterMessage = (content, level, dismiss) => {
        message.open({ type: level, content: content, duration: (dismiss ? 5 : 0) })
    }

    const retrieveCurrentUser = () => {
        if (currentUser && currentUser.uuid) {
            try {
                fetch(`/api/v1/customers/${currentUser.uuid}.json`)
                    .then(response => response.json())
                    .then(data => {

                        if (data.result == 'failure') {
                            if (data.error == "no-customer") {
                                setCurrentUser(null);
                                localStorage.removeItem('currentUserJSON');
                            }

                            Sentry.captureMessage(`Failure getting customer ${currentUser.uuid}`)
                        } else {
                            setCurrentUser(data.customer);
                        }
                    })
                    .catch(err => {
                        Sentry.captureException(err);
                    })
            } catch (error) {
                Sentry.captureException(error);
            }
        }

    }

    const debouncedUserFetch = () => {
        const userFetchAbortController = new AbortController();
        const waitUserFetchTimeout = setTimeout(retrieveCurrentUser, 500); //timeout delay

        return function () {
            userFetchAbortController.abort();
            clearTimeout(waitUserFetchTimeout); //Clearing the timeout
        };
    }

    // const debouncedIntentCreation = () => {
    //     const intentController = new AbortController();
    //     const waitIntentFetch = setTimeout(createPaymentIntent, 500); //timeout delay

    //     return function () {
    //         intentController.abort();
    //         clearTimeout(waitIntentFetch); //Clearing the timeout
    //     };
    // }

    // const debouncedPromocodeCheck = () => {
    //     const promocodeController = new AbortController();
    //     const waitPromocodeFetch = setTimeout(checkPromocode, 500); //timeout delay

    //     return function () {
    //         promocodeController.abort();
    //         clearTimeout(waitPromocodeFetch); //Clearing the timeout
    //     };
    // }

    useEffect(() => {
        const promocodeController = new AbortController();
        const waitPromocodeFetch = setTimeout(checkPromocode, 500); //timeout delay

        return function () {
            promocodeController.abort();
            clearTimeout(waitPromocodeFetch); //Clearing the timeout
        };
    }, [cart.promocode, cart.fulfilmentType, cart.total]);

    useEffect(() => {
        if (cart.getTotalInCents() > 0) {
            setStripeOptions({ ...stripeOptions, amount: cart.getTotalInCents() })
        }
    }, [cart.total]);

    // useEffect(() => {
    //     if (cart.metadata.fulfilmentType && cart.metadata.fulfilmentType != cart.fulfilmentType) {
    //         setOrderDetails({ ...orderDetails, fulfilment_type: cart.metadata.fulfilmentType });
    //     }
    // }, [cart.fulfilmentType])

    const clearPromocode = () => {
        cart.setDiscount(0);
        cart.setDiscountInfo("-");
        cart.setPromocode("")
        cart.clearSavedPromocode();
    }

    const checkPromocode = () => {
        if (!cart.promocode) {
            clearPromocode()
            return;
        }

        console.debug("checking promocode ", cart.promocode)

        try {
            fetch(`/api/v1/promocodes/check.json`, {
                method: 'POST',
                body: JSON.stringify({ promocode: { token: cart.promocode } }),
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    // cart.setThing('lastUsedPromocode', data.promocode)
                    applyDiscount(data.promocode);
                })
                .catch(err => {
                    Sentry.captureException(err);
                    clearPromocode()
                })
        } catch (error) {
            Sentry.captureException(error);
            clearPromocode()
        }
    }

    const applyDiscount = (promocode) => {
        if (!promocode) {
            clearPromocode()
            return;
        }

        const cartTotal = cart.getSubtotal();
        let eurDiscount = 0;
        let roundedEurDiscount = 0;
        let discountInWords = "";

        if (promocode.discount_percent) {
            eurDiscount = cartTotal * (promocode.discount_percent / 100.0);
            roundedEurDiscount = Math.round((eurDiscount + Number.EPSILON) * 100) / 100

            discountInWords = `${promocode.discount_percent}%`

            if (promocode.discount_percent == 100) {
                setPaymentDetails(prevPaymentDetails => ({ ...prevPaymentDetails, method: 'promocode' }))
            }
        } else if (promocode.cents_available_discount) {
            eurDiscount = promocode.cents_available_discount / 100.0
            roundedEurDiscount = Math.round((eurDiscount + Number.EPSILON) * 100) / 100

            if (roundedEurDiscount > cartTotal) {
                roundedEurDiscount = cartTotal;

                setPaymentDetails(prevPaymentDetails => ({ ...prevPaymentDetails, method: 'promocode' }))
            }

            discountInWords = `${roundedEurDiscount} €`
        } else if (promocode.offer_item) {
            discountInWords = `Free ${promocode.offer_item.name}`
        }

        // console.log(`TOTALS:\nSubtotal: ${cartTotal}\nDiscount: ${roundedEurDiscount}\nTotal: ${cartTotal - roundedEurDiscount}`)

        cart.setDiscountInfo(discountInWords);
        cart.setDiscount(roundedEurDiscount);

        if (roundedEurDiscount > 0) {
            cart.savePromocode();
        } else {
            cart.clearSavedPromocode();
        }

        if (promocodeInput.current) {
            promocodeInput.current.blur();

        }
    }

    const detailsFormChangeHandler = (changedValues, allValues) => {
        const mergedOpts = { ...orderDetails, ...changedValues }

        setOrderDetails(mergedOpts);
    };

    const placeOrderHandler = async (options) => {
        const shouldProceed = verifyAllDataBeforeOrdering();

        if (!shouldProceed) {
            return false;
        }
        else {
            const newOrder = await sendNewOrderRequest(options);

            if (newOrder && newOrder.uuid) {
                setCartToClear()

                window.location.href = `/orders/${newOrder.uuid}`;
            }
        }
    };

    const createOrderWithIntentAndReturnUuid = async (options = {}) => {
        if (isPaying) {
            return;
        }

        const shouldProceed = verifyAllDataBeforeOrdering();

        if (!shouldProceed) {
            return false;
        }

        setCheckoutLoading(true)
        setIsPaying(true)

        const finalPaymentOptions = {
            ...paymentDetails,
            promo_code: cart.promocode,
            method: (options?.payment?.method || cart.paymentMethod)
        }

        const newOrder = await fetch(`/api/v1/orders.json`, {
            method: 'POST',
            body: JSON.stringify({
                order: {
                    items: cart.itemsPayload(),
                    details: { ...orderDetails, fulfilment_type: cart.fulfilmentType },
                    payment: finalPaymentOptions,
                    customer: { ...customerDetails, uuid: (currentUser ? currentUser.uuid : null) },
                    address: addressDetails,
                    invoicing: invoicingDetails,
                    tip_amount: cart.tip,
                    stripe: options.stripe
                }
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setCheckoutLoading(false);
                setIsPaying(false);

                if (data.result === 'success') {
                    setCheckoutLoading(false);

                    const newOrder = data.object.order;

                    if (newOrder && newOrder.uuid) {
                        return newOrder;
                    } else {
                        return false;
                    }

                    // const order = data.object.order;
                    // localStorage.setItem('lastOrderCreatedUuid', order.uuid);
                    // setCartToClear

                    // return order;
                } else {
                    showToasterMessage(data.message, 'error', true);

                    Sentry.captureMessage(`Error placing order: '${data.message}'`)

                    setCheckoutLoading(false);
                    setIsPaying(false);

                    return false;
                }
            })
            .catch(err => {
                showToasterMessage(`Error while placing order`, 'error', true);

                setCheckoutLoading(false);
                setIsPaying(false);

                Sentry.captureException(err);

                return false;
            });

        return newOrder;
    };

    const verifyAllDataBeforeOrdering = () => {
        console.log("Verifying all data before ordering: ", customerDetails, orderDetails);

        if (cart.size == 0) {
            showToasterMessage(`Add a couple items to your cart before placing the order.`, 'warning', true);
            return false;
        }

        if (cart.fulfilmentType == 'tableservice') {
            if (!orderDetails.table_number || !orderDetails.table_number.trim()) {
                showToasterMessage(`Please give us a table number or a good way to find you`, 'warning', true);
                return false;
            }
        } else {
            if (!currentUser) {
                showToasterMessage(`To order Takeaway or Delivery please login first`, 'warning', false);
                return false;
            }
        }

        if (!customerDetails.name || customerDetails.name.trim().length < 2) {
            showToasterMessage(`We need your name to make sure food finds you`, 'warning', true);
            return false;
        }

        return true;
    }

    const placeOrderWithMBWay = async (evt) => {
        evt.preventDefault()

        if (!paymentDetails.mbway_number || !paymentDetails.mbway_number.length) {
            showToasterMessage(`If paying with MBWay, please write the number to pay with.`, 'warning', true);

            return false;
        }

        await placeOrderHandler({ payment: { method: "mbway" } })
    }

    const placeOrderWithPromocode = async (evt) => {
        evt.preventDefault()

        await placeOrderHandler({ payment: { method: "promocode" } })
    }

    const setCartToClear = () => {
        session.saveShouldClearCart(true);
        // cart.cleanupCart()

        // cart.setThing("cleanupCart", true)
    }

    const sendNewOrderRequest = async (options) => {
        setCheckoutLoading(true);
        setShowSpinner(true)

        const finalPaymentOptions = {
            ...paymentDetails,
            promo_code: cart.promocode,
            method: (options?.payment?.method || cart.paymentMethod)
        }

        return await fetch(`/api/v1/orders.json`, {
            method: 'POST',
            body: JSON.stringify({
                order: {
                    items: cart.itemsPayload(),
                    details: { ...orderDetails, fulfilment_type: cart.fulfilmentType },
                    payment: finalPaymentOptions,
                    customer: {
                        name: customerDetails.name,
                        phone_number: customerDetails.phone_number,
                        phone_verification_code: customerDetails.phone_verification_code,
                        email: customerDetails.email,
                        uuid: (currentUser ? currentUser.uuid : null)
                    },
                    address: addressDetails,
                    invoicing: invoicingDetails,
                    tip_amount: cart.tip
                }
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.result === 'success') {
                    const newOrder = data.object.order;

                    if (newOrder && newOrder.uuid) {
                        return newOrder;
                    } else {
                        return false;
                    }

                    //placedOrderCallback(data.object.order)
                    // setCartToClear
                    //localStorage.setItem('lastOrderCreatedUuid', data.object.order.uuid);
                    //history.push(`/orders/${data.object.order.uuid}`);
                } else {
                    showToasterMessage(data.message, 'error', true);

                    setCheckoutLoading(false);
                    setShowSpinner(false)

                    return false;
                }
            })
            .catch(err => {
                setShowSpinner(false)
                setCheckoutLoading(false);

                console.error('Error placing order!');
                Sentry.captureException(err);

                showToasterMessage(`Error while placing order`, 'error', true);

                return false;
            });
    };


    const scheduledOrderSelectOptions = () => {
        let options = [];

        if (menu.schedule_options.length > 0) {
            for (const schedule_opt of menu.schedule_options) {
                const hour = schedule_opt.slice(0, 2);
                const mins = schedule_opt.slice(2, 4);
                options.push({
                    key: `${hour}${mins}`,
                    name: `${hour}:${mins}`,
                    value: `${hour}${mins}`
                });
            }
        } else {
            let fromDate = new Date();
            let toDate = new Date();

            if (fromDate.getMinutes() >= 0 && fromDate.getMinutes() < 15) {
                fromDate.setHours(fromDate.getHours(), 15); // from hour:15
            } else if (fromDate.getMinutes() >= 15 && fromDate.getMinutes() < 30) {
                fromDate.setHours(fromDate.getHours(), 30); // from hour:30
            } else if (fromDate.getMinutes() >= 30 && fromDate.getMinutes() < 45) {
                fromDate.setHours(fromDate.getHours(), 45); // from hour:45
            } else if (fromDate.getMinutes() >= 45 && fromDate.getMinutes() <= 59) {
                fromDate.setHours(fromDate.getHours() + 1, 0); // from next hour:00
            }

            toDate.setHours(24, 0, 0, 0); // next midnight

            let cursorDate = fromDate;
            while (cursorDate < toDate) {
                const mins = ('0' + cursorDate.getMinutes()).slice(-2);
                const hour = ('0' + cursorDate.getHours()).slice(-2);
                options.push({
                    key: `${hour}${mins}`,
                    name: `${hour}:${mins}`,
                    value: `${hour}${mins}`
                });

                if (cursorDate.getMinutes() === 45) {
                    cursorDate.setHours(cursorDate.getHours() + 1, 0);
                } else {
                    cursorDate.setMinutes(cursorDate.getMinutes() + 15);
                }
            }

            options.splice(0, 2);
        }

        return options;
    }

    const formatEuroPrice = (price = 0.0, negative = false) => {
        try {
            if (negative) {
                price = price * -1;
            }

            return price.toLocaleString("pt-PT", { style: "currency", currency: "EUR" })
        } catch (e) {
            return (0.0).toLocaleString("pt-PT", { style: "currency", currency: "EUR" })
        }
    }

    return (<>
        <Form
            layout='horizontal'
            name='detailsForm'
            form={detailsForm}
            initialValues={orderDetails}
            onValuesChange={detailsFormChangeHandler}>

            <Flex gap={12} vertical align='stretch'>



                {cart.getSize() > 0 && <Flex vertical align='stretch'>
                    <Form.Item
                        name="special_instructions"
                        rules={[{ required: false }]}>

                        <Input placeholder="kitchen notes" />
                    </Form.Item>

                    {cart.fulfilmentType != 'tableservice' && <Flex gap={12} vertical align='stretch'>
                        <Form.Item
                            labelCol={{ xs: { span: 18 } }}
                            wrapperCol={{ xs: { span: 6 } }}
                            layout='horizontal'
                            label='Will you need napkins?'
                            name='include_napkins'
                            valuePropName='checked'>
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            labelCol={{ xs: { span: 18 } }}
                            wrapperCol={{ xs: { span: 6 } }}
                            layout='horizontal'
                            label='Should we include cutlery?'
                            name='include_cutlery'
                            valuePropName='checked'>
                            <Switch />
                        </Form.Item>

                        {menu.can_schedule &&
                            // <Col  xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                label='Want to schedule your order for later?'
                                name='scheduled_time'
                                rules={[{ required: false }]}>
                                <Select>
                                    <Select.Option value='asap'>Send it now</Select.Option>
                                    {scheduledOrderSelectOptions().map(opt => (
                                        <Select.Option key={opt.value} value={opt.value}>
                                            {opt.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>}
                    </Flex>}
                </Flex>}

                <Flex gap={24} style={{ marginBottom: '24px' }} vertical align='stretch'>

                    <Suspense fallback={<LoadingSpinner />}>
                        <LoginWidget
                            currentUser={currentUser}
                            setCurrentUser={setCurrentUser}
                            customerDetails={customerDetails}
                            setCustomerDetails={setCustomerDetails} />
                    </Suspense>

                    <Suspense fallback={<LoadingSpinner />}>
                        <InvoiceDetails
                            currentUser={currentUser}
                            setCurrentUser={setCurrentUser}
                            invoicingDetails={invoicingDetails}
                            setInvoicingDetails={setInvoicingDetails} />
                    </Suspense>

                    {(currentUser && cart.fulfilmentType == 'delivery' && menu.delivery_enabled) && <Suspense fallback={<LoadingSpinner />}>
                        <DeliveryDetails currentUser={currentUser} addressDetails={addressDetails} setAddressDetails={setAddressDetails} />
                    </Suspense>}
                </Flex>

                {cart.getSize() > 0 && <Flex gap={12} vertical align='stretch'>
                    <Form.Item name='customer_name'
                        style={{ flexGrow: 1 }}
                        rules={[
                            {
                                required: true,
                                min: 2,
                                type: 'string',
                                whitespace: true,
                                message: "Who's eating?"
                            }
                        ]}>
                        <Input addonBefore="Name" size="large" value={customerDetails.name} onChange={(evt) => { setCustomerDetails({ ...customerDetails, name: evt.target.value }) }} />
                    </Form.Item>

                    {cart.fulfilmentType == 'tableservice' && <Form.Item
                        name='table_number'
                        style={{ flexGrow: 1 }}
                        rules={[
                            {
                                required: true,
                                min: 1,
                                type: 'string',
                                whitespace: true,
                                message: "Where are you seating?"
                            }
                        ]}>
                        <Input addonBefore="Table" size="large" />
                    </Form.Item>}

                </Flex>}

                <Flex gap={24} vertical align='stretch'>
                    <Form.Item
                        style={{ flexGrow: 1 }}
                        name='promocode'
                        rules={[{ required: false }]}>
                        <Input ref={promocodeInput} size='large' autoCapitalize='off' autoCorrect='false' spellCheck="false" autoComplete="off" addonAfter={(cart.discountInfo && cart.discount > 0) ?
                            ((cart.discountInfo && `(${cart.discountInfo})  `) + ((cart.discount && cart.getTotal() > 0) ? formatEuroPrice(cart.discount, true) : "")) : ""
                        } addonBefore="Promocode" value={cart.promocode}
                            onChange={(evt) => { cart.setPromocode(evt.target.value.trim()) }} />
                    </Form.Item>
                </Flex>

                <Flex gap={24} vertical align='stretch' style={{ marginBottom: '24px' }}>
                    <ConfigProvider
                        theme={{
                            token: {
                                borderRadius: "0px 21px 21px 0px",
                                colorPrimary: "#000000",
                                controlHeight: "40px",
                                colorPrimaryHover: "#444444"
                            }
                        }}>
                        <Button className={styles.fullPlaceOrder} size='large' type="primary" onClick={placeOrderWithPromocode}>
                            <span style={{ padding: "0px 15px" }}>Order with Promocode</span>
                        </Button>
                    </ConfigProvider>

                    {cart.getTotalInCents() > 0 &&
                        <Space.Compact style={{ width: '100%' }}>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        borderRadius: "21px 0px 0px 21px",
                                        colorBorder: "#000000",
                                        controlHeight: "42px"
                                    },
                                }}>
                                <Form.Item
                                    style={{ flexGrow: 1 }}
                                    name='mbway_number'
                                    rules={[{ required: false }]}>
                                    <Input size='large'
                                        className={styles.mbwayInput}
                                        prefix={<img src={mbWayLogo} className={styles.mbwayInputIcon} />}
                                        inputMode='tel'
                                        autoComplete='tel'
                                        type="tel"
                                        spellCheck="false"
                                        value={paymentDetails.mbway_number}
                                        onChange={(evt) => { setPaymentDetails({ ...paymentDetails, mbway_number: evt.target.value }) }} />
                                </Form.Item>
                            </ConfigProvider>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        borderRadius: "0px 21px 21px 0px",
                                        colorPrimary: "#000000",
                                        controlHeight: "40px",
                                        colorPrimaryHover: "#444444",
                                        fontSize: "0.8rem"
                                    }
                                }}>
                                <Button size='large' type="primary" onClick={placeOrderWithMBWay}>
                                    <span style={{ padding: "0px 0.8rem 0rem 0.5rem", fontSize: '0.7rem', lineHeight: '0.9rem' }}>
                                        Pay with<br />
                                        <strong style={{ fontSize: '0.9rem' }}>MBWay</strong>
                                    </span>
                                </Button>
                            </ConfigProvider>
                        </Space.Compact>}

                    {(cart.getTotalInCents() > 0 && stripeOptions.amount > 50) && <Elements stripe={stripePromise} options={stripeOptions}>
                        <Suspense fallback={<LoadingSpinner />}>
                            <PaymentWidget
                                currentUser={currentUser}
                                paymentDetails={paymentDetails}
                                stripeClientSecret={stripeOptions.clientSecret}
                                createOrderWithIntentAndReturnUuid={createOrderWithIntentAndReturnUuid}
                                verifyAllDataBeforeOrdering={verifyAllDataBeforeOrdering} />
                        </Suspense>
                    </Elements>}
                </Flex>
            </Flex>
        </Form>

        <Spin spinning={showSpinner} fullscreen />

    </>
    );
};

export default OrderDetails;
