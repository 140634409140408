import React, { useContext } from 'react';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
const ButtonGroup = Button.Group;

import { CartContext } from 'contexts/CartContext';

const AddToCart = ({ count, item, addOns, canAddToCart, itemFullKey, disabled = false, shouldAddToCart = false, size = 'large', vertical = false, hideCount = false }) => {
    const cart = useContext(CartContext)

    const addItemToCartHandler = (evt) => {
        evt.preventDefault();

        // console.log("adding to cart", item, addOns)

        if (shouldAddToCart) {
            cart.addToCart(item, addOns)
            return;
        } else {
            if (shouldAddToCart || canAddToCart()) {
                cart.addToCart(item, addOns)
                return;
            } else {
                return;
            }
        }
    }

    const removeItemToCartHandler = (evt) => {
        evt.preventDefault();

        let itemKey
        let localAddOns = { ...addOns }

        if (itemFullKey) {
            itemKey = itemFullKey
        } else {
            let itemPath = item.map(i => i.id);

            if (addOns) {
                let mainOption
                if (localAddOns.main) {
                    mainOption = localAddOns.main
                    delete localAddOns.main
                    localAddOns[mainOption.id] = mainOption
                }
                const addOnsIds = Object.values(localAddOns).map(a => parseInt(a));
                itemPath = itemPath.concat(addOnsIds);
            }

            itemKey = itemPath.join('.')
        }

        cart.removeFromCart(itemKey)
    }

    return (count > 0 ?
        (vertical ?
            <Space.Compact size={size} direction="vertical">
                <Button disabled={disabled} type='default' onClick={addItemToCartHandler} icon={<PlusOutlined />} />
                {!hideCount && <Button type='default' disabled style={{ color: 'black', cursor: 'default', padding: '0' }}>{count}</Button>}
                <Button disabled={disabled} type='default' onClick={removeItemToCartHandler} icon={<MinusOutlined />} />
            </Space.Compact> :
            <ButtonGroup size={size}>
                <Button disabled={disabled} type='default' onClick={removeItemToCartHandler} icon={<MinusOutlined />} />
                {!hideCount && <Button type='default' disabled style={{ color: 'black', cursor: 'default' }}>{count}</Button>}
                <Button disabled={disabled} type='default' onClick={addItemToCartHandler} icon={<PlusOutlined />} />
            </ButtonGroup>) : <>
            <ButtonGroup size={size}>
                <Button disabled={disabled} size={size} type='default' onClick={addItemToCartHandler} style={{ background: '' }}>Add one</Button>
            </ButtonGroup></>)
}

export default AddToCart;
