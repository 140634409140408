import React from 'react'

import glutenIcon from 'images/allergens/gluten.png'
import lactoseIcon from 'images/allergens/lactose.png'
import nutsIcon from 'images/allergens/nuts.png'
import crustaceansIcon from 'images/allergens/crustaceans.png'
import eggIcon from 'images/allergens/egg.png'
import soyaIcon from 'images/allergens/soya.png'
import fishIcon from 'images/allergens/fish.svg'
import celeryIcon from 'images/allergens/celery.svg'
import sulfitesIcon from 'images/allergens/sulfites.svg'
import sesameIcon from 'images/allergens/sesame.svg'
import mustardIcon from 'images/allergens/mustard.svg'
import molluscsIcon from 'images/allergens/molluscs.svg'

import * as styles from './InfoSection.module.scss'

const AllergensPanel = ({ allergens }) => {
    return (
        <dl className={styles.allergensList}>
            <dt><img src={glutenIcon} className={styles.allergenItem} /> Gluten</dt>
            <dd><ul>{allergens.contains_gluten.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={lactoseIcon} className={styles.allergenItem} /> Lactose</dt>
            <dd><ul>{allergens.contains_lactose.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={nutsIcon} className={styles.allergenItem} /> Nuts</dt>
            <dd><ul>{allergens.contains_nuts.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={crustaceansIcon} className={styles.allergenItem} /> Crustceans</dt>
            <dd><ul>{allergens.contains_crustaceans.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={eggIcon} className={styles.allergenItem} /> Egg</dt>
            <dd><ul>{allergens.contains_egg.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={soyaIcon} className={styles.allergenItem} /> Soy</dt>
            <dd><ul>{allergens.contains_soya.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={fishIcon} className={styles.allergenItem} /> Fish</dt>
            <dd><ul>{allergens.contains_fish.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={celeryIcon} className={styles.allergenItem} /> Celery</dt>
            <dd><ul>{allergens.contains_celery.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={sesameIcon} className={styles.allergenItem} /> Sesame</dt>
            <dd><ul>{allergens.contains_sesame.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={sulfitesIcon} className={styles.allergenItem} /> Sulfites</dt>
            <dd><ul>{allergens.contains_sulfites.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={mustardIcon} className={styles.allergenItem} /> Mustard</dt>
            <dd><ul>{allergens.contains_mustard.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>

            <dt><img src={molluscsIcon} className={styles.allergenItem} /> Molluscs</dt>
            <dd><ul>{allergens.contains_moluscs.map(mi => <li key={mi}>{mi}</li>)}</ul></dd>
        </dl>
    )
}

export default AllergensPanel