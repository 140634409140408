import React from 'react'

import { Flex, Typography, Tooltip } from 'antd';
const { Text } = Typography;


// import glutenIcon from 'images/allergens/gluten.png';
// import lactoseIcon from 'images/allergens/lactose.png';
// import nutsIcon from 'images/allergens/nuts.png';
// import crustaceansIcon from 'images/allergens/crustaceans.png';
// import eggIcon from 'images/allergens/egg.png';
// import soyaIcon from 'images/allergens/soya.png';
// import sulfitesIcon from 'images/allergens/sulfites.svg';
// import mustardIcon from 'images/allergens/mustard.svg'
// import molluscsIcon from 'images/allergens/molluscs.svg'
// import celeryIcon from 'images/allergens/celery.svg'
// import fishIcon from 'images/allergens/fish.svg'
// import sesameIcon from 'images/allergens/sesame.svg'

import glutenIcon from 'images/new/allergens/gluten.svg';
import lactoseIcon from 'images/new/allergens/milk.svg';
import nutsIcon from 'images/new/allergens/nuts.svg';
import crustaceansIcon from 'images/new/allergens/crustaceans.svg';
import eggIcon from 'images/new/allergens/eggs.svg';
import soyaIcon from 'images/new/allergens/soya.svg';
import sulfitesIcon from 'images/new/allergens/sulphites.svg';
import mustardIcon from 'images/allergens/mustard.svg'
import molluscsIcon from 'images/new/allergens/abalone.svg'
import celeryIcon from 'images/new/allergens/celery.svg'
import fishIcon from 'images/new/allergens/fish.svg'
import sesameIcon from 'images/new/allergens/sesame.svg'

import * as menuStyles from '../Menu/Menu.module.scss';

const AllergensIcons = ({ item }) => {
  const hasAny = () => {
    return Object.values(item.allergens).includes(true)
  }

  const fullList = [
    item.allergens.contains_gluten && "Gluten",
    item.allergens.contains_lactose && "Lactose",
    item.allergens.contains_nuts && "Nuts",
    item.allergens.contains_crustaceans && "Crustaceans",
    item.allergens.contains_egg && "Egg",
    item.allergens.contains_soya && "Soya",
    item.allergens.contains_celery && "Celery",
    item.allergens.contains_fish && "Fish",
    item.allergens.contains_sesame && "Sesame",
    item.allergens.contains_sulfites && "Sulfites",
    item.allergens.contains_mustard && "Mustard",
    item.allergens.contains_moluscs && "Molluscs"
  ].filter(el => el != false).join(", ")

  return (hasAny() ? <Flex vertical align='center'>
    <Tooltip title={`Contains: ${fullList}`}>
      <Text style={{
        position: 'absolute',
        top: '-1.5rem',
        background: 'rgba(255, 255, 255, 0.7)',
        padding: '0 0.3rem'
      }}>Allergens</Text>
    </Tooltip>

    <Flex gap={6} justify='center' style={{ position: 'relative', top: '0.3rem' }}>
      <Tooltip title={`Contains: ${fullList}`}>
        {item.allergens.contains_gluten && <img src={glutenIcon} className={menuStyles.itemAllergensIcon} alt='Contains Gluten' title='Gluten allergy icon' />}

        {item.allergens.contains_lactose && <img src={lactoseIcon} className={menuStyles.itemAllergensIcon} alt='Contains Lactose' title='Lactose allergy icon' />}

        {item.allergens.contains_nuts && <img src={nutsIcon} className={menuStyles.itemAllergensIcon} alt='Contains Nuts' title='Nuts allergy icon' />}

        {item.allergens.contains_crustaceans && <img src={crustaceansIcon} className={menuStyles.itemAllergensIcon} alt='Contains Crustaceans' title='Crustaceans allergy icon' />}

        {item.allergens.contains_egg && <img src={eggIcon} className={menuStyles.itemAllergensIcon} alt='Contains Egg' title='Egg allergy icon' />}

        {item.allergens.contains_soya && <img src={soyaIcon} className={menuStyles.itemAllergensIcon} alt='Contains Soya' title='Soya allergy icon' />}

        {item.allergens.contains_celery && <img src={celeryIcon} className={menuStyles.itemAllergensIcon} alt='Contains Celery' title='Celery allergy icon' />}

        {item.allergens.contains_fish && <img src={fishIcon} className={menuStyles.itemAllergensIcon} alt='Contains Fish' title='Fish allergy icon' />}

        {item.allergens.contains_sesame && <img src={sesameIcon} className={menuStyles.itemAllergensIcon} alt='Contains Sesame' title='Sesame allergy icon' />}

        {item.allergens.contains_sulfites && <img src={sulfitesIcon} className={menuStyles.itemAllergensIcon} alt='Contains Sulfites' title='Sulfites allergy icon' />}

        {item.allergens.contains_mustard && <img src={mustardIcon} className={menuStyles.itemAllergensIcon} alt='Contains Mustard' title='Mustard allergy icon' />}

        {item.allergens.contains_moluscs && <img src={molluscsIcon} className={menuStyles.itemAllergensIcon} alt='Contains Molluscs' title='Molluscs allergy icon' />}
      </Tooltip>
    </Flex>

  </Flex > : <Flex vertical align='center'>
    <Text>No allergens at all</Text>
  </Flex>);
}

export default AllergensIcons