import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import * as styles from './HeaderBar.module.scss';

import { MenuContext } from '../../../contexts/MenuContext';

const HeaderBar = () => {
    const { menu } = useContext(MenuContext);

    const [backgroundStyle, setBackgroundStyle] = useState({});
    const [foregroundStyle, setForegroundStyle] = useState({});

    useEffect(() => {
        if (menu?.header_background_image_url) {
            setBackgroundStyle({
                backgroundImage: `url(${menu.header_background_image_url})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'repeat'
            });
        }

        if (menu?.header_foreground_image_url) {
            setForegroundStyle({
                backgroundImage: `url(${menu.header_foreground_image_url})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            });
        }
    }, [menu]);

    return (
        <div style={backgroundStyle}>
            <h1 style={foregroundStyle} className={classNames(styles.menuBrandName)}>{menu?.name}</h1>
        </div>
    )
}

export default HeaderBar