import React, { useState, createContext, useEffect, useCallback, useMemo } from 'react';

export const CartContext = createContext({
    size: 0,
    total: 0.0,
    deliveryFee: 0.0,
    promocode: '',
    discount: 0.0,
    tip: 0.0,
    metadata: {},
    discountInfo: '',
    getTotal: () => { },
    getSize: () => { },
    addToCart: () => { },
    removeFromCart: () => { },
    setFulfilmentType: () => { },
    setPreselectedAddress: () => { },
    setInternalKey: () => { },
    setPromocode: () => { },
    isInternal: () => { },
    initCart: () => { },
    getDeliveryFee: () => { },
    hasDeliveryFee: () => { },
    addDeliveryFee: () => { },
    setTip: () => { },
    numberOfItemsInCart: () => { }
});

const CartContextProvider = ({ children }) => {
    const timeBetweenCartCleanups = 1000 * 60 * 60 * 4; // 4 hours

    const [initialMetadata, setInitialMetadata] = useState({ deliveryFee: 5.35 });
    const [hasBeenInitialized, setHasBeenInitialized] = useState(false);
    const [size, setSize] = useState(0);
    const [total, setTotal] = useState(0.0);

    const [items, setItems] = useState({});
    const [filteredItems, setFilteredItems] = useState([]);
    const [metadata, setMetadata] = useState({});
    const [deliveryFee, setDeliveryFee] = useState(0.0);
    const [promocode, setPromocode] = useState('');
    const [fulfilmentType, setFulfilmentType] = useState();
    const [discount, setDiscount] = useState(0.0);
    const [discountInfo, setDiscountInfo] = useState("")

    const [tip, setTip] = useState(0.0);

    const [stripeIntent, setStripeIntent] = useState({});
    const [paymentMethod, setPaymentMethod] = useState(null);

    const accumulatorFunc = (val, acc) => acc + val;
    const filteredItemsForCheckout = useCallback((menuItem) => (menuItem.item.is_available && menuItem.quantity > 0 && menuItem.item.available_in.includes(fulfilmentType)), [fulfilmentType]);
    const filteredItemsForCart = (menuItem) => (menuItem.item.is_available && menuItem.quantity > 0);

    useEffect(() => {
        const cartSize = getCartSize()
        const cartTotal = getCartTotal()

        setSize(cartSize);
        setTotal(cartTotal);
    }, [filteredItems, discount, deliveryFee, tip, fulfilmentType]);

    // const itemsFilteredValues = useMemo(() => {
    //     Object.values(items).filter(filteredItemsForCart)
    //     // .sort((itemA, itemB) => itemA.key.localeCompare(itemB.key));
    // }, [items]);

    useEffect(() => {
        setFilteredItems(Object.values(items).filter(filteredItemsForCart))

        if (hasBeenInitialized) {
            saveItemsToLocalStorage(items);
        }
    }, [items]);

    useEffect(() => {
        if (metadata && hasBeenInitialized) {
            saveMetadataToLocalStorage();
        }
    }, [metadata]);

    useEffect(() => {
        if (deliveryFee) {
            setMetadata({ ...metadata, deliveryFee: deliveryFee })
        }
    }, [deliveryFee]);

    useEffect(() => {
        if (fulfilmentType) {
            setMetadata({ ...metadata, fulfilmentType: fulfilmentType })

            if (fulfilmentType == "delivery") {
                setDeliveryFee(5.35)
            } else {
                setDeliveryFee(0);
            }
        }
    }, [fulfilmentType]);

    // useEffect(() => {
    //     const existingPromocode = getThing('lastUsedPromocode');

    //     console.debug("Promocode changed to ", promocode, " Existing is ", existingPromocode)

    //     if (promocode) {
    //         if (!existingPromocode || promocode != existingPromocode) {
    //             setThing('lastUsedPromocode', promocode)
    //         }
    //     }


    // }, [promocode]);

    const savePromocode = () => {
        setThing('lastUsedPromocode', promocode)
    }

    const clearSavedPromocode = () => {
        setThing('lastUsedPromocode', '')
    }

    const initCart = (initial = {}, clearCart = false) => {
        console.debug("Initializing Cart with ", initial)

        const mergedMetadata = refreshMetadataFromLocalStorage(initial);
        setFulfilmentType(mergedMetadata.fulfilmentType)

        const existingPromocode = getThing('lastUsedPromocode');
        if (existingPromocode) {
            setPromocode(existingPromocode)
        }

        const lastCartSet = getThing("lastCartSet");
        const shouldCleanupCart = clearCart || getThing("cleanupCart");

        if (shouldCleanupCart) {
            setThing("cleanupCart", false);

            setItems({});
            // setThing("lastCartSet", undefined);
            // setThing("tableNumber", undefined);
        } else if (lastCartSet && (new Date() - new Date(lastCartSet)) > timeBetweenCartCleanups) {
            setThing("cleanupCart", false);

            setItems({});
            setThing("lastCartSet", undefined);
            // setThing("tableNumber", undefined);
        } else {
            setItems(getItemsFromLocalStorage());
        }

        setHasBeenInitialized(true);
    }

    const getFilteredItems = (localItems) => {
        localItems.filter(lineItem => {
            if (lineItem.quantity == 0) {
                return false;
            } else if (fulfilmentType && lineItem.item.available_in.length) {
                return lineItem.item.available_in.includes(fulfilmentType)
            } else {
                return true
            }
        })
    }

    function setLocalStorage(key, value) {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error("Error saving to localstorage: ", e)
            // catch possible errors:
            // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
        }
    }

    function getLocalStorage(key, initialValue) {
        try {
            const storageItemJson = window.localStorage.getItem(key);
            const storageObject = JSON.parse(storageItemJson)

            //console.info(`CartContext.getLocalStorage(${key})=`, result)

            //if (key == "las-gringas.items") {
            //  result = result.filter(el => el != null)
            //}

            return (storageObject || initialValue)
        } catch (e) {
            console.error("Error reading from localstorage: ", e)
            // if error, return initial value
            return initialValue;
        }
    }

    const refreshCart = (items) => {
        saveItemsToLocalStorage();
        setSize(getCartSize());
        setTotal(getCartTotal());
    }

    const localStorageScopedKey = (key) => {
        return `foodriders.${key}`;

        // if (localStorageKeys?.menuSlug) { //&& localStorageKeys?.userId
        //   return `${localStorageKeys.menuSlug}.${key}` // .${localStorageKeys.userId}
        // } else {
        //   return null
        // }
    }

    const refreshMetadataFromLocalStorage = (initial = {}) => {
        const metadataFromStorage = getLocalStorage(localStorageScopedKey("metadata"), {})

        const mergedInitial = {
            ...metadataFromStorage,
            ...metadata,
            ...initial,
        }

        setMetadata(mergedInitial)

        return mergedInitial
    }

    const getItemsFromLocalStorage = () => {
        return getLocalStorage(localStorageScopedKey("items"), {})
    }

    const saveMetadataToLocalStorage = () => {
        setLocalStorage(localStorageScopedKey("metadata"), metadata);
    }

    const saveItemsToLocalStorage = (localItems) => {
        setLocalStorage(localStorageScopedKey("items"), localItems);
        setThing("lastCartSet", new Date().toISOString());
    }

    const euroPriceForContext = (context, itemOrVariant) => {
        const centsPrice = centsPriceForContext(context, itemOrVariant);

        return centsPrice / 100;
    }

    const centsPriceForContext = (context, itemOrVariant) => {
        let selected_price;

        switch (context) {
            case 'delivery':
                selected_price = itemOrVariant.prices['delivery'] || itemOrVariant.prices['takeaway'] || itemOrVariant.prices['tableservice'] || itemOrVariant.prices['default'];
                break;
            case 'takeaway':
                selected_price = itemOrVariant.prices['takeaway'] || itemOrVariant.prices['delivery'] || itemOrVariant.prices['tableservice'] || itemOrVariant.prices['default'];
                break;
            case 'tableservice':
                selected_price = itemOrVariant.prices['tableservice'] || itemOrVariant.prices['takeaway'] || itemOrVariant.prices['delivery'] || itemOrVariant.prices['default'];
                break;
            default:
                selected_price = itemOrVariant.prices['tableservice'] || itemOrVariant.prices['takeaway'] || itemOrVariant.prices['delivery'] || itemOrVariant.prices['default'];
                break;
        }

        return selected_price?.total || itemOrVariant.price || 0;
    }

    const hasContextPrices = (item) => {
        return (item.prices != null)
    }

    const itemVariants = (item) => {
        if (item.variant_groups.length > 0) {
            const variants = item.variant_groups.map(group => group.options).flat(Infinity)

            if (variants.length > 0) {
                return variants
            } else {
                return []
            }
        } else {
            return []
        }
    }

    const addItemToCartHandler = (item, initialAddOns) => {
        // console.info('[addItemToCartHandler] item=', item, ' initialAddOns=', initialAddOns, " existing: ", items);

        if (!item || item.length == 0) {
            return;
        }

        const itemObj = item[0]

        const initialAddOnsVals = Object.values(initialAddOns).map(el => parseInt(el))

        let itemPath = [itemObj.id]
        itemPath = itemPath.concat(initialAddOnsVals);
        const itemKey = itemPath.join('.')

        let newItems = { ...items };
        let lineForItem = newItems[itemKey];

        if (lineForItem) {
            const newQty = lineForItem.quantity + 1;
            const newLineTots = lineForItem.linePartial * newQty;

            newItems[itemKey] = { ...lineForItem, quantity: newQty, lineTotal: newLineTots }
        } else {
            let mainOption
            if (initialAddOns.main) {
                mainOption = initialAddOns.main
                delete initialAddOns.main
                initialAddOns[mainOption.id] = mainOption
            }

            const initialAddOnsKeys = Object.keys(initialAddOns).map(el => parseInt(el))

            let addOnsObjects = itemObj.variant_groups
                .filter(group => initialAddOnsKeys.includes(group.id))
                .map(group => group.options.find(option => option.id == initialAddOns[group.id]))
                .map(option => ({ id: option.id, name: option.name, prices: option.prices }))

            lineForItem = {
                quantity: 1,
                key: itemKey,
                item: {
                    id: itemObj.id,
                    name: itemObj.name,
                    prices: itemObj.prices,
                    available_in: itemObj.available_in,
                    is_available: itemObj.is_available,
                    availability_status: itemObj.availability_status,
                    variant_groups: itemObj.variant_groups
                },
                variant: mainOption,
                addOns: addOnsObjects,
                initialAddOns: initialAddOns,
                fullPath: itemPath
            }
            lineForItem.linePartial = linePartial(lineForItem);
            lineForItem.lineTotal = lineForItem.linePartial;

            newItems = { ...newItems, [itemKey]: lineForItem }
        }

        // console.debug("Adding item to cart: ", itemKey, lineForItem)

        setItems(newItems)
        setFilteredItems(Object.values(newItems).filter(filteredItemsForCart))
    }

    const itemsPayload = () => {
        return filteredItems.filter(filteredItemsForCheckout).map(lineItem => ({
            quantity: lineItem.quantity,
            item: { id: lineItem.item.id },
            variant: lineItem.variant,
            addOns: lineItem.addOns.map(addOn => ({ id: addOn.id }))
        }))
    }

    const removeItemFromCartHandler = (itemKey) => {
        if (!itemKey || !items[itemKey]) {
            return;
        }

        let newItems = { ...items };
        const itemQuantity = newItems[itemKey].quantity;

        if (itemQuantity > 1) {
            newItems[itemKey] = {
                ...newItems[itemKey],
                quantity: (itemQuantity - 1),
                lineTotal: (newItems[itemKey].linePartial * (itemQuantity - 1))
            }
        } else {
            // const {itemKey, ...newItems} = items; // newItems gets all but key2
            newItems = { ...items }
            delete newItems[itemKey]
        }

        setItems(newItems)
        setFilteredItems(Object.values(newItems).filter(filteredItemsForCart))
    }

    const linePartial = (item) => {
        let itemContextedBasePrice = 0

        if (hasContextPrices(item.item)) {
            itemContextedBasePrice = euroPriceForContext(fulfilmentType, item.item)
        } else if (item.variant) {
            itemContextedBasePrice = euroPriceForContext(fulfilmentType, item.variant)
        }

        let addOnsTotalPrice = item.addOns
            .map(variant => euroPriceForContext(fulfilmentType, variant))
            .reduce(accumulatorFunc, 0);

        const totalPartialPrice = itemContextedBasePrice + addOnsTotalPrice;

        return totalPartialPrice
    }

    const setThing = (key, value) => {
        const keyForStorage = localStorageScopedKey(`things.${key}`)

        setLocalStorage(keyForStorage, value);
    }

    const getThing = (key) => {
        const keyForStorage = localStorageScopedKey(`things.${key}`)

        return getLocalStorage(keyForStorage);
    }

    const savePreselectedAddress = preselectedAddress => {
        setMetadata({ ...metadata, preselectedAddress: preselectedAddress });
    };

    const addDeliveryFee = (deliveryFee) => {
        setDeliveryFee(deliveryFee);
    };

    const getDeliveryFee = () => {
        return deliveryFee;
    }

    const hasDeliveryFee = () => {
        return (fulfilmentType == 'delivery' && !!deliveryFee);
    }

    const setInternalKey = internalKey => {
        setMetadata({
            ...metadata,
            internalKey: internalKey
        });
    };

    const isInternalOrder = () => {
        return metadata.internalKey && metadata.internalKey != '';
    };

    const isDelivery = () => {
        return (fulfilmentType == 'delivery')
    }

    const isTableService = () => {
        return (fulfilmentType == 'tableservice')
    }

    const getCartSize = () => {
        const filteredForCheckout = filteredItems.filter(filteredItemsForCheckout)
        if (filteredForCheckout.length == 0) {
            return 0;
        } else {
            return filteredForCheckout.map(el => el.quantity).reduce(accumulatorFunc, 0);
        }
    }

    const getCartTotal = () => {
        const itemsSubtotal = getSubtotal();
        let subtotalMinusDiscount = itemsSubtotal - discount;

        if (subtotalMinusDiscount < 0) {
            subtotalMinusDiscount = 0
        }

        let discountedSubtotalPlusDeliveryFee = subtotalMinusDiscount;

        if (metadata.fulfilmentType == 'delivery') {
            discountedSubtotalPlusDeliveryFee += deliveryFee;
        }

        return discountedSubtotalPlusDeliveryFee;
    };

    const getTotalInCents = () => {
        return Math.round(getCartTotal() * 100)
    }

    const getSubtotal = () => {
        const filteredForCheckout = filteredItems.filter(filteredItemsForCheckout)
        if (filteredForCheckout.length == 0) {
            return 0;
        }

        const withoutDeliveryFee = filteredForCheckout.map(el => el.lineTotal).reduce(accumulatorFunc, 0);
        // let totalWithDelivery = withoutDeliveryFee;

        // if (fulfilmentType == 'delivery') {
        //     totalWithDelivery = withoutDeliveryFee + deliveryFee;
        // }

        return withoutDeliveryFee;
    }

    const cleanupCart = () => {
        setItems({})
        // localStorage.removeItem('lastOrderCreatedUuid')
        //setMetadata({})
    }

    const keyForItem = (item, addOns = {}) => {
        let itemPath = item.map(i => i.id);
        let selectedAddOnsIds = Object.values(addOns).map(aoid => parseInt(aoid))
        itemPath = itemPath.concat(selectedAddOnsIds);

        return itemPath.join('.')
    }

    const numberOfItemsInCart = (itemKey) => {
        if (filteredItems.length == 0) {
            return 0;
        }

        // const foundInCart = filteredForCart[itemKey]

        // const withoutDeliveryFee = filteredForCart.map(el => el.lineTotal).reduce(accumulatorFunc, 0);

        let mainItemKey;
        let cartMainProds;

        if (itemKey.includes('.*')) {
            mainItemKey = parseInt(itemKey.split('.')[0]);
            cartMainProds = Object.keys(items).map(e => e.split('.')[0]);
        } else {
            mainItemKey = itemKey;
            cartMainProds = Object.keys(items);
        }

        const cartQuantities = Object.values(filteredItems).map(e => e.quantity);

        let prodQuantCount = {};

        cartMainProds.forEach(function (el, index) {
            if (prodQuantCount[el]) {
                prodQuantCount[el] = prodQuantCount[el] + cartQuantities[index];
            } else {
                prodQuantCount[el] = cartQuantities[index];
            }
        });

        const lineForItem = prodQuantCount[mainItemKey] || 0

        return lineForItem;
    };

    const provider = {
        items: items,
        itemsPayload: itemsPayload,
        metadata: metadata,
        total: total,
        size: size,
        tip: tip,
        setTip: setTip,
        promocode,
        setPromocode,
        discount,
        setDiscount,
        discountInfo,
        setDiscountInfo,
        cleanupCart: cleanupCart,
        initCart: initCart,
        refreshCart: refreshCart,
        getTotal: getCartTotal,
        getSubtotal,
        getSize: getCartSize,
        linePartial: linePartial,
        addToCart: addItemToCartHandler,
        removeFromCart: removeItemFromCartHandler,
        setPreselectedAddress: savePreselectedAddress,
        setInternalKey: setInternalKey,
        isInternal: isInternalOrder,
        setInitialMetadata: setInitialMetadata,
        initialMetadata: initialMetadata,
        hasDeliveryFee: hasDeliveryFee,
        getDeliveryFee: getDeliveryFee,
        addDeliveryFee: addDeliveryFee,
        isTableService: isTableService,
        isDelivery: isDelivery,
        numberOfItemsInCart,
        setStripeIntent, stripeIntent,
        getTotalInCents,
        paymentMethod, setPaymentMethod,
        setThing, getThing,
        filteredItems,
        getFilteredItems,
        keyForItem,
        fulfilmentType,
        setFulfilmentType,
        clearSavedPromocode,
        savePromocode
    };

    return (
        <CartContext.Provider value={provider}>{children}</CartContext.Provider>
    );
};

export default CartContextProvider;
