'use client';

export const FoodridersDefaultConfig = {
  stripeKey: 'pk_test_51ISghOB5pixtzBz8bDvNROsIVMm41dJzqZRREP7UyfLuC9Xyf7vM0fMGVzS0eAuzVigniYt1KXOl9IBez37nL2N000IjIBcEab',
  sentryDsn: "https://56cb44b86dda0e1a49d94732152b2ca7@o609634.ingest.us.sentry.io/4507211540463616",
  sentryEnvironment: "unknown",
  setupSentry: true,
  enableSentryDebug: false,
  sentryCaptureConsole: false,
  sentryCaptureConsoleLevels: ['warn', 'error'],
  sentryBrowserTracing: true,
  sentryReplay: true,
  showSentryDialog: true
}

// const FoodridersDomainToEnv = {
//   'foodriders.co': 'production',
//   'www.foodriders.co': 'production',
//   'fdrd.rs': 'production',
//   'beta.foodriders.co': 'staging',
//   'localhost:3000': 'development'
// }

export const FoodridersEnvConfig = {
  'foodriders.co': {
    stripeKey: 'pk_live_51ISghOB5pixtzBz8l9L6BHNsKiF9svtncm9uUXfAKwadafbeuf08hyHpJwvDlLBnLxzmumB9sS60yNtrjZ8dYRAG00kYeqZsgI',
    railsEnv: 'production',
    sentryEnvironment: "production",
    showSentryDialog: true
  },
  'www.foodriders.co': {
    stripeKey: 'pk_live_51ISghOB5pixtzBz8l9L6BHNsKiF9svtncm9uUXfAKwadafbeuf08hyHpJwvDlLBnLxzmumB9sS60yNtrjZ8dYRAG00kYeqZsgI',
    railsEnv: 'production',
    sentryEnvironment: "production",
    showSentryDialog: true
  },
  'fdrd.rs': {
    stripeKey: 'pk_live_51ISghOB5pixtzBz8l9L6BHNsKiF9svtncm9uUXfAKwadafbeuf08hyHpJwvDlLBnLxzmumB9sS60yNtrjZ8dYRAG00kYeqZsgI',
    railsEnv: 'production',
    sentryEnvironment: "production",
    showSentryDialog: true
  },
  'beta.foodriders.co': {
    railsEnv: 'staging',
    sentryEnvironment: "beta",
    sentryCaptureConsole: false,
    sentryBrowserTracing: false,
    sentryReplay: false,
    sentryCaptureConsoleLevels: ['warn', 'error', 'info'],
    showSentryDialog: false
  },
  'nu.foodriders.co': {
    stripeKey: 'pk_live_51ISghOB5pixtzBz8l9L6BHNsKiF9svtncm9uUXfAKwadafbeuf08hyHpJwvDlLBnLxzmumB9sS60yNtrjZ8dYRAG00kYeqZsgI',
    railsEnv: 'production',
    sentryEnvironment: "nu",
    enableSentryDebug: false,
    sentryCaptureConsole: false,
    sentryBrowserTracing: false,
    sentryReplay: false,
    sentryCaptureConsoleLevels: ['warn', 'error', 'info'],
    showSentryDialog: false
  },
  'localhost:3000': {
    railsEnv: 'development',
    sentryEnvironment: "dev",
    setupSentry: false,
    enableSentryDebug: false,
    sentryCaptureConsole: false,
    sentryBrowserTracing: false,
    sentryReplay: false,
    showSentryDialog: false
  },
  'foodriders.test': {
    railsEnv: 'development',
    sentryEnvironment: "dev",
    setupSentry: false,
    enableSentryDebug: false,
    sentryCaptureConsole: false,
    sentryBrowserTracing: false,
    sentryReplay: false,
    showSentryDialog: false
  }
}

const FoodridersConfig = {
  ...FoodridersDefaultConfig,
  ...FoodridersEnvConfig[window.location.host]
};

export default FoodridersConfig;