// extracted by mini-css-extract-plugin
var _1 = "HIAxEkdVGPBS0aW8AGVA";
var _2 = "OPFKCYStY4yIsuUtf5cJ";
var _3 = "_OyIGJKS1GBAv1tAkQ0m";
var _4 = "ayCTAuWtfYRGz8HCF9jz";
var _5 = "L5fJoKTLgQMkbrFZ5mIu";
var _6 = "PgckR49ACou4nqbW7VzF";
var _7 = "poKyPQ5KZNzdsOhtHnkL";
var _8 = "VHRVU_a6U_Le7jMhUwbo";
var _9 = "ntwfBJ99wnLm8pHUZXQu";
var _a = "r0r04Pa66sDRPodmZuzQ";
var _b = "e9_f6Pj7M9MQuqER3CGF";
var _c = "aSeS925wBVevQJgbsC3q";
var _d = "EzMe85M5FzC2Y03ffIfj";
var _e = "ZmrAFgwy5_8zQFoI2Vh6";
var _f = "Utk58Jksn0mgjFpifWAO";
var _10 = "lSE5764sFX4w9El3BCS_";
var _11 = "GbT9n45b41jvkvkGiCCh";
var _12 = "c3_E3MPqeAUf5wXT_CUw";
var _13 = "dj4bvp95u3E7xKo53BRZ";
var _14 = "bvZID5A6k6GGdJCSGe5l";
var _15 = "xchUWEQeFTaCVnX39Pb3";
var _16 = "r1rTnOv2M4oqOnOlcJa0";
var _17 = "nEs9gO6zEHYRRbvmvJ5P";
var _18 = "ZuHmKnjtzmDsJLd9oPOw";
var _19 = "QAeusXVu2Harel2oMv2z";
var _1a = "BSrOKmFoZwtuoC0d5CLD";
var _1b = "HblaDmkt1x_blWUvOJ59";
var _1c = "RHZxxX9lEMzK3g8XfG8g";
var _1d = "Auq1cRPBNJz1HBEWiCQa";
var _1e = "iINMZSROkEvxExM8yCLE";
var _1f = "gsHhyBDlHmxMEF3zi5YC";
var _20 = "WYpptPNPThudLqLMsynl";
var _21 = "cVNdAWUjGVGfe5kX_x3O";
export { _1 as "anime", _2 as "cartDrawerContainer", _3 as "cartPrice", _4 as "cartPriceBig", _5 as "checkoutNowButton", _6 as "finalizeOrder", _7 as "foodridersPillButton", _8 as "footerBar", _9 as "footerBarContainer", _a as "footerContainer", _b as "fullPlaceOrder", _c as "goBackButton", _d as "itemCartButtons", _e as "mbwayInput", _f as "mbwayInputIcon", _10 as "mr1", _11 as "offlinePaymentNotice", _12 as "orderDetailsActions", _13 as "orderDetailsContainer", _14 as "orderDetailsFormLabel", _15 as "orderDetailsSection", _16 as "paddingLeftOneRem", _17 as "paddingRightOneRem", _18 as "placeOrder", _19 as "promocodeInput", _1a as "removeFromCart", _1b as "selectDropdown", _1c as "shoppingCartButtonsCol", _1d as "shoppingCartSqueezedColumn", _1e as "shoppingCartTable", _1f as "shoppingCartTableBody", _20 as "tipInput", _21 as "toTheRight" }
