import React, { createContext, useEffect, useState } from 'react'

const AVAILABLE_LANGS = ['en', 'pt']
const DEFAULT_LANG = 'pt'

export const LanguageContext = createContext({
	lang: 'pt',
})

export default function LanguageContextProvider({ value, children }) {
	const [language, setLanguage] = useState('pt')

	const toggleLanguage = () => {
		setLanguage(language => (language === 'pt' ? 'en' : 'pt'))
	}

	return <LanguageContext.Provider value={{ language, setLanguage, toggleLanguage }}>{children}</LanguageContext.Provider>
}
