import React from 'react';

import * as styles from './Errors.module.scss';
import flames from 'images/errors/flames.png';

const NotFound = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.notFound}>
        <h3>ooops, something went wrong</h3>
        <h5>please try to refresh or go back and try again<br />let us know in case it happens again</h5>

        <img className={styles.dino} src={flames} />

        <h6>Av. Infante Dom Henrique 151<br />1959-012 Lisboa<br />913 593 205</h6>
      </div>
    </div>
  )
}

export default NotFound