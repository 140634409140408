import React, { useContext, useEffect, useState } from 'react'

import { CartContext } from '../../../contexts/CartContext'

import AddToCart from './AddToCart'

import classNames from 'classnames';

import { Empty } from 'antd';

import LoadingSpinner from '../../shared/LoadingSpinner'

import * as styles from './ShoppingCart.module.scss'

const ShoppingCart = ({ }) => {
    const localCart = useContext(CartContext);
    // const [tip, setTip] = useState('');

    const formatEuroPrice = (price = 0.0, negative = false) => {
        try {
            if (negative) {
                price = price * -1;
            }

            return price.toLocaleString("pt-PT", { style: "currency", currency: "EUR" })
        } catch (e) {
            return (0.0).toLocaleString("pt-PT", { style: "currency", currency: "EUR" })
        }
    }

    const itemQualifiedName = (item) => {
        let fullName = `${item.item.name}`

        let variantName = (item.variant ? ` (${item.variant.name})` : "")

        const addOnsEnum = item.addOns.map(a => a.name).join(" + ")
        let addOnsNames = (item.addOns.length > 0 ? ` (+ ${addOnsEnum})` : "")

        return <span>
            {item.item.name}
            {variantName && <><br />{variantName}</>}
            {addOnsNames && <><br />{addOnsNames}</>}
        </span>
    }

    // const onTipChange = (e) => {
    //     const inputValue = e.target.value.replace(',', '.').replace(/[^0-9\.]/g, ''); // Remove non-numeric characters
    //     let tipValue = parseFloat(inputValue)

    //     if (isNaN(tipValue)) {
    //         tipValue = 0.0
    //     }

    //     cart.setTip(tipValue)
    //     setTip(inputValue)
    // };

    const itemCanBeOrdered = (item, fulfilmentType) => {
        return item.available_in.includes(fulfilmentType) && item.is_available;
    }

    return (localCart.filteredItems.length ? <>
        <table className={styles.shoppingCartTable}>
            <tbody class={styles.shoppingCartTableBody}>
                {localCart.filteredItems.map(lineItem => (
                    <tr key={lineItem.key} style={{ color: (itemCanBeOrdered(lineItem.item, localCart.fulfilmentType)) ? "initial" : "#ccc" }}>
                        <td className={styles.shoppingCartButtonsCol}>
                            <AddToCart
                                shouldAddToCart={true}
                                item={[lineItem.item]}
                                itemFullKey={lineItem.key}
                                addOns={lineItem.initialAddOns}
                                // count={localCart.numberOfItemsInCart(lineItem.key || (lineItem.addOns.length > 0 ? `${lineItem.item.id}.*` : [lineItem.item.id].join('.')))}
                                count={lineItem.quantity}
                                vertical={true}
                                hideCount={true}
                                size="middle" />
                        </td>
                        <td>
                            <strong>{itemQualifiedName(lineItem)}</strong>
                            <br />
                            <span className={styles.cartPrice}>
                                <strong>{lineItem.quantity}&nbsp;&times;</strong>&nbsp;{formatEuroPrice(lineItem.linePartial)}
                            </span>
                            {(localCart.fulfilmentType && !itemCanBeOrdered(lineItem.item, localCart.fulfilmentType) && <>
                                <br />
                                <span className={styles.cartPrice}>
                                    <strong style={{ color: "initial" }}>Not available in {localCart.fulfilmentType}</strong>
                                </span>
                            </>)}
                        </td>
                        <td className={styles.toTheRight}><span className={classNames([styles.cartPrice, styles.paddingLeftOneRem, styles.cartPriceBig])}>{formatEuroPrice(lineItem.lineTotal)}</span></td>
                    </tr>
                ))}
                {(localCart.hasDeliveryFee() || (localCart.promocode && localCart.discount > 0)) && <tr>
                    <td></td>
                    <td style={{ verticalAlign: 'bottom' }}>Subtotal</td>
                    <td className={styles.toTheRight}><span className={classNames([styles.cartPrice, styles.paddingLeftOneRem, styles.cartPriceBig])}>{formatEuroPrice(localCart.getSubtotal())}</span></td>
                </tr>}
                {(localCart.promocode && localCart.discount > 0) && <tr>
                    <td></td>
                    <td>
                        Discount ({(localCart.discountInfo && localCart.discountInfo)})
                        <br />
                        <span className={styles.cartPrice}>{localCart.promocode}</span>


                    </td>
                    <td className={styles.toTheRight}>
                        <span className={classNames([styles.cartPrice, styles.paddingLeftOneRem, styles.cartPriceBig])}>
                            {localCart.discount ? formatEuroPrice(localCart.discount, true) : ""}
                        </span>
                    </td>
                </tr>}
                {localCart.hasDeliveryFee() &&
                    <tr>
                        <td></td>
                        <td style={{ verticalAlign: 'bottom' }}>Delivery Fee</td>
                        <td className={styles.toTheRight}><span className={classNames([styles.toTheRight, styles.cartPrice, styles.paddingLeftOneRem, styles.cartPriceBig])}>{formatEuroPrice(localCart.getDeliveryFee())}</span></td>
                    </tr>
                }


                {/* <tr>
                        <td colSpan="2"></td>
                        <td>Staff Tip</td>
                        <td style={{ textAlign: 'right' }}>
                            <input
                                type="tel"
                                spellCheck="false"
                                autoComplete="off"
                                value={tip} //formatTipValue(tip.toFixed(2).replace('.', ''))}
                                onChange={onTipChange}
                                onClick={(evt)=>{ const t=evt.target; t.setSelectionRange(0, t.value.length); }}
                                className={styles.tipInput}
                            />
                        </td>
                        <td className={styles.cartPrice}>{ formatEuroPrice(cart.tip) }</td>
                    </tr> */}
                <tr>
                    <td></td>
                    <td style={{ verticalAlign: 'bottom' }}>Total</td>
                    <td className={styles.toTheRight}><span className={classNames([styles.cartPrice, styles.paddingLeftOneRem, styles.cartPriceBig])}>{formatEuroPrice(localCart.getTotal())}</span></td>
                </tr>
            </tbody>
        </table>


    </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Cart is empty" />);
}

export default ShoppingCart
