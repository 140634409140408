// extracted by mini-css-extract-plugin
var _1 = "jPFzqMLd7j3ysY9wayoL";
var _2 = "VaNpv0I6TDEQF0HPwH3w";
var _3 = "LmGPGuIQxtdb04zmEpks";
var _4 = "QiyBmYM6mliPVzDWQ9Vw";
var _5 = "Z3zD4OHfLCclpX8so1Bl";
var _6 = "wsw5vcjVtK9tpmC1xyNF";
var _7 = "bNLojqYnVnxcGsj7HMmZ";
var _8 = "IieEr0snw4vDHHtVtGqF";
var _9 = "Fw0O1kO0a_wZUu6vbpWv";
var _a = "dahrqde_1YLxwsxh2Dox";
var _b = "sS944Ys9fGnESqjh0FMy";
var _c = "dJlQtzpfdKPWCjwQc9M8";
var _d = "HKekHTYlg4RAm9c4z0iP";
var _e = "gIJb02g2_R2biJgjzDGS";
var _f = "mWNIJAiSx7G4Y0arfgGD";
var _10 = "pYIBoSlajDAyyYCiqkFz";
var _11 = "HhYWi2oaQax2Wl1y5Igt";
var _12 = "br572naDL_jlyfui3ucP";
export { _1 as "aboutSectionPanel", _2 as "aboutSectionTitle", _3 as "allergenItem", _4 as "allergensList", _5 as "anticon", _6 as "b", _7 as "bi", _8 as "infoBar", _9 as "infoButton", _a as "infoIcon", _b as "infoSectionBackLink", _c as "infoSectionCard", _d as "langSelectionActive", _e as "languageToggle", _f as "menuInfo", _10 as "menuInfoAboutText", _11 as "menuSectionCaret", _12 as "menuSectionCaretActive" }
