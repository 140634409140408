import React, { createContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useVisibilityChange, useLocalStorage } from "@uidotdev/usehooks";

import * as Sentry from '@sentry/react';


export const MenuContext = createContext({
  menu: null,
  slug: null
})

export default function MenuContextProvider({ value, children, initialMenus }) {
  const params = useParams();
  const [slug, setSlug] = useState(params.slug);
  const [menu, setMenu] = useState((params.slug == value.menu?.slug ? value.menu : initialMenus?.find(m => m.slug == params.slug)) || {});

  const documentVisible = useVisibilityChange();
  const [lastMenuFetch, saveLastMenuFetch] = useLocalStorage("lastMenuFetch", {});

  const timeBetweenMenuFetches = 60 * 1000; // 5 minutes

  useEffect(() => {
    // console.debug("MenuContextProvider useEffect [documentVisible]", documentVisible, slug, menu, initialMenus)

    if (!documentVisible) {
      // console.debug("Document not visible. Not fetching menu")
      return;
    }

    // console.debug("Document visible")

    if (slug) {
      if (!menu?.slug || menu?.slug != slug) {
        // console.debug(`No menu slug (${menu?.slug}) or diff slug (${slug}). Fetching`)
        fetchMenu();
      } else if (lastMenuFetch[slug] && (new Date() - new Date(lastMenuFetch[slug])) > timeBetweenMenuFetches) {
        // console.debug("Got menu and matching slug. Has last fetch and is time to fetch again. Fetching")
        fetchMenu();
      }
      // else {
      //   console.debug("Got menu and matching slug. No last fetch or not time to fetch again. Not fetching")
      // }
    }
  }, [documentVisible])

  const fetchMenu = () => {
    try {
      // console.info(`Fetching menu for ${slug}`)
      fetch(`/api/v1/menus/${slug}.json?template=minimal`)
        .then(response => response.json())
        .then(data => {
          setMenu(data.menu);
          saveLastMenuFetch({ ...lastMenuFetch, [slug]: new Date().toISOString() });
        })
        .catch(err => {
          Sentry.captureException(err)
        })
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  return <MenuContext.Provider value={{ menu }}>{children}</MenuContext.Provider>
}
