// extracted by mini-css-extract-plugin
var _1 = "zKW7Tp4rgzB0VwmwhRft";
var _2 = "xHxrr91Fjc5HHv_vhjgB";
var _3 = "_1NJI4s9UH_Z8zNaSrTk";
var _4 = "CqqdTQH1eScGoEwNMhbI";
var _5 = "vxMMEPcMxFPNT38SqqdE";
var _6 = "bf3H528djuQsD76RHJvV";
var _7 = "SIk2hB4XnI6buODgnQca";
var _8 = "LHxOi0HWRQnOYR_1eb6s";
var _9 = "CmQUqBfM18X9ZVEr0x_y";
var _a = "B21wZryeH_ycVpHwk2eU";
var _b = "nn6qAhuNlJdJAPpAYzSh";
var _c = "sCrhb2c7KPz9quNZr40w";
var _d = "q1DZUjWQD3YkOy4zCTye";
var _e = "chT90INWBijB4BcIsqwm";
var _f = "Ay7r9R7GmcIPQRSCt7wJ";
var _10 = "lVAilgKB2RaeE25etPzS";
var _11 = "k11tiZ6llIcUT_auBmQG";
var _12 = "izATtpgVkMDblc4bKt6H";
var _13 = "I3R9iNAuU_uSCVPloeiQ";
var _14 = "NmzwN9liGEiI6C1og5El";
var _15 = "nnAPTWy_dbLSwLwA5tRV";
var _16 = "pOeInq4poN23wIVs1g30";
var _17 = "zjHUzhRxr9Q27Xlqfxci";
var _18 = "eythgdPyOhO8JLcsT7pJ";
var _19 = "NeeB2gjQ72oFwqmMCSAH";
var _1a = "QN7eqYLADn2gC8ChS78J";
var _1b = "sHWYwCMuU9mZigjMEi9A";
var _1c = "Sn3t4kF8lsWGs7efGOES";
var _1d = "MNHJackENxDYDIH8xXo4";
var _1e = "WZKVgIQSIpzaMCQpLQmF";
var _1f = "Jo0voYEcZBBdwzKedGzG";
var _20 = "IFGYaKFuLk3cqlWibxTw";
var _21 = "PFOpoAoQciR7LVZ3Y2pC";
var _22 = "uAKkQBtIf3eBNF689Njx";
var _23 = "eL4zF1zujsMrNsV5Sxdj";
var _24 = "nt0xDRKHvk1tboF66ByE";
var _25 = "yZccn7iBTII62NguE4pF";
var _26 = "fdo6kYBQuwMEdLxNaa6w";
var _27 = "Ud0md0ktr7VpJcxcluW7";
var _28 = "uQgOHjy7rY1lhYv5QXFB";
var _29 = "dzaSfcHt9Rxl77Dj0q8c";
var _2a = "FHQvOPSG8dLg8ZFpvvxo";
var _2b = "ElsbNefZHnlREDafaxde";
var _2c = "urRhV79rSEc1mKZvWtrK";
var _2d = "jnhpFHCqX1sL6KfnMlTd";
export { _1 as "addToCartSegment", _2 as "ant-collapse-expand-icon", _3 as "ant-collapse-header", _4 as "anticon", _5 as "blueDetails", _6 as "cartSectionBody", _7 as "cartSectionIcon", _8 as "deliveryTabIcon", _9 as "fulfilmentTypeTab", _a as "fulfilmentTypeTabsContent", _b as "inlineIcon", _c as "itemAllergensIcon", _d as "itemCard", _e as "itemCardWrapper", _f as "itemFlamesIcon", _10 as "itemFlamesIconNotSpicy", _11 as "menu-item-price", _12 as "menuCollapseContainer", _13 as "menuContainer", _14 as "menuItemContainer", _15 as "menuItemContent", _16 as "menuItemContentExpanded", _17 as "menuItemDescription", _18 as "menuItemName", _19 as "menuItemNameUnavailable", _1a as "menuItemPrice", _1b as "menuItemPriceOOS", _1c as "menuItemTitle", _1d as "menuItemUnavailable", _1e as "menuItemVariant", _1f as "menuItemVariantsContainer", _20 as "menuMainContainer", _21 as "menuSectionCaret", _22 as "menuSectionCaretActive", _23 as "menuSectionDivider", _24 as "menuSectionFinalizeOrder", _25 as "menuSectionTitle", _26 as "menuSubSectionTitle", _27 as "ribbonWrapper", _28 as "sectionIcon", _29 as "serviceTypeSelectorContainer", _2a as "serviceTypeSelectorItem", _2b as "serviceTypeSelectorItemDisabled", _2c as "serviceTypeSelectorItemIcon", _2d as "serviceTypeSelectorItemSelected" }
