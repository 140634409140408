import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Helmet } from "react-helmet";

import { createConsumer } from '@rails/actioncable';
import { useVisibilityChange, useLocalStorage } from "@uidotdev/usehooks";
import * as Sentry from '@sentry/react';

import { SessionContext } from 'contexts/SessionContext';

import classNames from 'classnames';

import { Flex, Empty, Steps } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

import DynamicState from './components/states/DynamicState';

import * as styles from './Order.module.scss'
import * as landingStyles from '../Landing/Landing.module.scss';

const Order = (props) => {
    const session = useContext(SessionContext);
    // const { uuid } = useParams();
    // const history = useHistory();

    const [uuid, setUuid] = useState(props.uuid)
    const [order, setOrder] = useState(props.order)

    const [fetching, setFetching] = useState(false)

    const cableConsumer = useRef();
    const cableSubscription = useRef();

    const documentVisible = useVisibilityChange();
    const [lastOrderFetch, saveLastOrderFetch] = useLocalStorage("lastOrderFetch", null);

    const timeBetweenOrderFetches = 30 * 1000; // 5 minutes

    useEffect(() => {
        if (order) {
            setupChannelSubscription()
            setCartToClear();
        }
    }, [])

    useEffect(() => {
        // console.debug("useEffect [documentVisible]", documentVisible, lastOrderFetch, order, uuid)

        if (uuid && !order) {
            // console.debug("Got uuid. No order")
            fetchOrder();
        } else if ((!lastOrderFetch || (new Date() - new Date(lastOrderFetch)) > timeBetweenOrderFetches)) {
            // console.debug("No last fetch or time to fetch again")
            fetchOrder()
        }
        //  else {
        //     console.debug("Not fetching order")
        // }

        // setTimeout(fetchOrder, 5000);
    }, [documentVisible])

    const setCartToClear = () => {
        session.saveShouldClearCart(true);
    }

    // useEffect(() => {
    //     console.debug("useEffect [documentVisible, menu, slug]", documentVisible, lastMenuFetch, menu, slug)

    //     if (slug && !menu) {
    //         console.debug("Got slug. No menu")
    //         fetchMenu();
    //     } else if ((!lastMenuFetch || (new Date() - new Date(lastMenuFetch)) > timeBetweenMenuFetches)) {
    //         console.debug("No last fetch or time to fetch again")
    //         fetchMenu()
    //     } else {
    //         console.debug("Not fetching menu")
    //     }
    // }, [documentVisible, menu, slug])

    const fetchOrder = useCallback(() => {
        if (fetching || !uuid) return;

        setFetching(true)

        try {
            console.info("Fetching order: ", uuid)
            fetch(`/api/v1/orders/${uuid}.json?template=customer`)
                .then((response) => response.json())
                .then((data) => {
                    console.info("got order from fetch")

                    setOrder(data.order)
                    setFetching(false)
                    saveLastOrderFetch(new Date().toISOString());

                    // setTimeout(fetchOrder, 5000);
                })
                .catch((err) => {
                    setFetching(false)
                    // setTimeout(fetchOrder, 1000);

                    Sentry.captureException(err);
                    console.info("got error in fetching order")
                })
        } catch (error) {
            setFetching(false)
            // setTimeout(fetchOrder, 1000);

            Sentry.captureException(error);
            console.info("got error in fetching order")
        }
    }, [uuid, fetching])

    const setupChannelSubscription = () => {
        if (cableConsumer.current && cableSubscription.current) {
            return;
        }
        try {
            cableConsumer.current = createConsumer('/cable')
            cableSubscription.current = cableConsumer.current.subscriptions.create({
                channel: 'OrderChannel',
                order_uuid: order.uuid
            }, {
                received: data => {
                    console.info("got order from cable")
                    setOrder(data.order)
                    saveLastOrderFetch(new Date().toISOString());

                },
                disconnected: () => {
                    console.info("got disconnected from cable")
                    // setTimeout(fetchOrder, 1000);
                }
            })
        } catch (error) {
            console.info("got error in cable")

            // setTimeout(fetchOrder, 1000);

            Sentry.captureException(error);
        }
    }

    // onClick={goBackToMenu}
    const goBackToMenu = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        let destination;

        if (document.referrer) {
            destination = document.referrer
        } else {
            destination = '/menus'
        }

        window.open(destination, '_blank').focus();

        return;
    }

    return (<>
        <Helmet>
            <title>FoodRiders - Order #{order?.short_code}</title>
        </Helmet>

        {order ? <Flex vertical gap={16} align='align' style={{ width: '100%' }}>
            <Flex justify="space-between" align="baseline" className={classNames(styles.topBarContainer)}>
                <a onClick={goBackToMenu} className={styles.backButton}><FontAwesomeIcon icon={faAngleLeft} /> Menu</a>

                <header className={classNames(landingStyles.header, styles.orderHeader)}>
                    <h1>#{order.short_code}<br />for {order.customer.name}</h1>
                </header>

                <div className={`${styles.backButton} ${styles.rightButton}`}>{order.has_receipt && <a href={`/f/${order.short_code}`} target="_blank" >Invoice <FontAwesomeIcon icon={faFileArrowDown} /></a>}</div>
            </Flex>

            <Flex vertical gap={16} align='center' style={{ width: '95%', margin: '0 auto' }}>

                {/* <Steps
                    progressDot
                    current={1}
                    items={[
                        {
                            title: 'Order Placed',
                            // description: 'We got your order',
                        },
                        {
                            title: 'Kitchen Confirmed',
                            // description: 'The cooks got your order',
                        },
                        {
                            title: 'Cooking',
                            // description: "We may use fire in this step",
                        },
                        {
                            title: 'Delivering',
                            // description: 'Food and drinks soon with you',
                        },
                        {
                            title: 'All Done',
                            // description: 'Thanks for ordering!',
                        }
                    ]}
                /> */}

                {/* <hr className={styles.dashedSpacer} /> */}

                <DynamicState order={order} />
            </Flex>

            <Flex justify="center" align="baseline" className={classNames(styles.topBarContainer)}>
                <footer className={classNames(landingStyles.header, styles.orderHeader)}>
                    <p className={styles.footer}>
                        Thanks for ordering Foodriders<br />
                        <a href="tel:+351913593205">+351 913 593 205</a>
                    </p>
                </footer>
            </Flex>

        </Flex> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Loading Order..." />}
    </>);
}

export default Order;