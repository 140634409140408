import React from 'react'
import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

{/* <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 48,
          }}
          spin
        />
      }
    /> */}

const LoadingSpinner = ({size, tip}) => (<Spin size={size} indicator={<LoadingOutlined spin />} tip={tip}>{content}</Spin>)

export default LoadingSpinner