// import React from "react";
// import { createRoot } from 'react-dom/client';

// import OrderingRoot from "../components/OrderingRoot";

// document.addEventListener("DOMContentLoaded", () => {
//   const mountPoint = document.getElementById("ordering-mount")
//   const { dataset } = mountPoint;
//   const props = JSON.parse(dataset.props);

//   let rootDiv = document.createElement("div");
//   rootDiv.setAttribute("id", "root");
//   document.body.appendChild(rootDiv);

//   let root = createRoot(rootDiv);
//   root.render(<OrderingRoot {...props} />);
// });

import ReactOnRails from 'react-on-rails';

import OrderingRoot from "../components/OrderingRoot";

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  OrderingRoot,
});
