import React, { createContext, useEffect, useState } from 'react'

import { FoodridersDefaultConfig, FoodridersEnvConfig } from '../src/FoodridersConfig'

export const ConfigContext = createContext({
  config: FoodridersDefaultConfig
})

export default function ConfigContextProvider({ value, children }) {
  const [config, setConfig] = useState(FoodridersDefaultConfig)

  useEffect(() => {
    if (window?.location?.host) {
      setConfig({
        ...FoodridersDefaultConfig,
        ...FoodridersEnvConfig[window.location.host]
      })
    }
  }, [])

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}
