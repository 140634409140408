import React, { useContext, useState, lazy, Suspense, useCallback } from 'react';

import { CartContext } from 'contexts/CartContext';

import * as Sentry from '@sentry/react';

const AddressPicker = lazy(() => import('./AddressPicker'));

import {
    Select,
    Form,
    Input,
    Row,
    Col,
    Typography,
    Modal,
    Button,
    Flex
} from 'antd';
const { Text, Title } = Typography;

import * as styles from './ShoppingCart.module.scss';


const DeliveryDetails = ({ currentUser, addressDetails, setAddressDetails }) => {
    const cart = useContext(CartContext);

    const [modalOpen, setModalOpen] = useState(false);

    const [showMessageAddressOutsideRadius, setShowMessageAddressOutsideRadius] = useState(false);
    const [showingAddressDetails, setShowingAddressDetails] = useState(false);

    const [addressForm] = Form.useForm();

    const handleGeocodedResultHandler = useCallback((result, pip) => {
        // pip is true if one of these is true:
        //  * point-inside-polygon
        //  * is internal order
        //  * customer is allowed to bypass geofence
        if (pip) {
            addressPickerResultCallback(result);
            getDeliveryQuote(result.center);
            setShowMessageAddressOutsideRadius(false);
        } else {
            setShowMessageAddressOutsideRadius(true);
        }
    }, []);

    const addressPickerResultCallback = result => {
        const postcode = result.context.filter(el => el.id.startsWith('postcode'))[0]?.text || ""
        const place = result.context.filter(el => el.id.startsWith('place'))[0]?.text || ""

        const placeNameParts = result.place_name.split(/,\s?/)
        const removedParts = placeNameParts.splice(-3, 3)
        placeNameParts.push(`${postcode} ${place}`)
        placeNameParts.push(removedParts[removedParts.length - 1])

        setAddressDetails({
            ...addressDetails,
            street_name: result.text,
            street_number: result.address,
            center: result.center,
            place_name: placeNameParts.join(", "),
            city: place,
            postcode: postcode
        });
        addressForm.setFieldsValue({
            street_name: result.text,
            street_number: result.address,
            city: place,
            postcode: postcode
        });

        if (!showingAddressDetails) {
            setShowingAddressDetails(true);
        }
    };

    const getDeliveryQuote = (center) => {
        try {
            fetch(`/api/v1/deliveries/quote.json`, {
                method: 'POST',
                body: JSON.stringify({ delivery: { center: center } }),
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    cart.addDeliveryFee(data.object.total_delivery_quoted_fee);
                })
                .catch(err => {
                    Sentry.captureException(err);
                })
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    const addressFormChangeHandler = (changedValues, allValues) => {
        setAddressDetails({ ...addressDetails, ...changedValues });
    };

    const showModal = () => {
        setModalOpen(true);
    };

    const hideModal = () => {
        setModalOpen(false);
    };

    const onDeliveryDetailsFinish = (values) => {
        console.log("delivery form submit: ", values);
    };

    const onDeliveryModalSave = () => {
        console.log("delivery modal save");
        hideModal()
    }

    // useEffect(() => {
    //     if (cart.metadata.preselectedAddress) {
    //         setAddressDetails({
    //             ...addressDetails,
    //             street_name: cart.metadata.preselectedAddress.text,
    //             street_number: cart.metadata.preselectedAddress.address,
    //             center: cart.metadata.preselectedAddress.center,
    //             place_name: cart.metadata.preselectedAddress.place_name
    //         });
    //         addressForm.setFieldsValue({
    //             street_name: cart.metadata.preselectedAddress.text,
    //             street_number: cart.metadata.preselectedAddress.address
    //         });
    //     }
    // }, [cart.metadata.preselectedAddress]);

    return (<Flex gap={24}>
        <Flex justify='center' style={{ flexGrow: 1 }}>
            {!modalOpen && <Button block type='primary' onClick={showModal}>
                Set Your Delivery Address
            </Button>}
        </Flex>

        <Modal
            title="Your Address"
            open={modalOpen}
            onCancel={hideModal}
            footer={null}>

            {!cart.metadata.preselectedAddress && <>
                <Suspense fallback={<div>Loading...</div>}>
                    <AddressPicker
                        handleGeocodedResultCallback={handleGeocodedResultHandler}
                        geofenceBypass={currentUser?.geofence_bypass} />
                </Suspense>


                {(!showMessageAddressOutsideRadius && !showingAddressDetails) &&
                    <Text type='secondary'>
                        Try to find your address in the map above first. If you can't
                        find your address it means that our current delivery area may
                        not include it yet. If you think this is an error{' '}
                        <a onClick={() => setShowingAddressDetails(true)}>
                            click here to add it anyway
                        </a>{' '}
                        and have the order manually accepted or denied by our team.<br /><br />
                    </Text>}
            </>}

            {showMessageAddressOutsideRadius ?
                <Title type="warning" strong={true} level={5}>We're sorry, we don't deliver to your address yet.</Title> :

                <Form
                    layout='vertical'
                    name='addressForm'
                    form={addressForm}
                    onValuesChange={addressFormChangeHandler}
                    initialValues={addressDetails}
                    onFinish={onDeliveryDetailsFinish} >

                    <div style={{ display: showingAddressDetails || cart.metadata.preselectedAddress ? 'block' : 'none' }}>
                        <Row gutter={8}>
                            <Col span={16}>
                                <Form.Item
                                    label='Street name'
                                    name='street_name'
                                    rules={[{
                                        required: true,
                                        message: 'Please give us a street name!'
                                    }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label='Number'
                                    name='street_number'
                                    rules={[{
                                        required: true,
                                        message: 'Please give us a street number!'
                                    }]} >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={8}>
                                <Form.Item
                                    label='Floor/Door'
                                    name='floor'
                                    rules={[
                                        {
                                            required: false
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label='Postcode'
                                    name='postcode'
                                    placeholder='1200-123'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label='City'
                                    name='city'
                                    placeholder="Lisboa"
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    label='How to deliver'
                                    name='delivery_method'
                                    rules={[
                                        {
                                            required: false
                                        }
                                    ]}
                                >
                                    <Select popupClassName={styles.selectDropdown}>
                                        <Select.Option value='to_the_door'>
                                            To my door
                                        </Select.Option>
                                        <Select.Option value='meet_outside'>
                                            Meet outside
                                        </Select.Option>
                                        <Select.Option value='leave_at_door'>
                                            Leave at door
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label='Extra delivery instructions'
                                    name='delivery_instructions'
                                    rules={[
                                        {
                                            required: false
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>}

            <Flex justify='flex-end' >
                <Button type='primary' onClick={onDeliveryModalSave}>Save</Button>
            </Flex>
        </Modal>
    </Flex>)
}

export default DeliveryDetails